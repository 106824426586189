<template>
  <div class="action-tool">
    <div class="item">
      <el-button :disabled="actioning" @click="handleActions('sitdown')" size="mini" class="bottom">坐下</el-button>
    </div>
   <div class="item">
     <el-button :disabled="actioning"  @click="handleActions('hello')" size="mini" class="bottom">打招呼</el-button>
   </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    actioning: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleActions(action) {
      this.$emit('handleActions', {type: 'movement', action})
    }
  },
  mounted() {

  },
  beforeDestroy() {

  },
}
</script>

<style scoped lang="scss">
  .action-tool{
    position: fixed;
    left: 0;
    bottom: 280px;
    width: 80px;
    z-index: 5;
    .item{
      margin-bottom: 12px;
    }
  }
</style>
