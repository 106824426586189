<template>
  <div>
    <button @click="sendMessageToIframe">sendToIframe</button>
<!--    <iframe src="vr/index.html" frameborder="0" ref="iframeRef"></iframe>-->
  </div>
</template>

<script>
  export default {
    name: "index",
    data() {
      return {}
    },
    methods: {
      // 发送消息到iframe
      sendMessageToIframe() {
        this.$refs.iframeRef.contentWindow.postMessage({type: 'add', params: {name: 'test'}});
      },
      // 接收来自iframe的消息
      receiveMessageFromIframe(event) {
        if (!this.$refs.iframeRef) {
          return;
        }
        if (event.source !== this.$refs.iframeRef.contentWindow) {
          return;
        }
        this.msg = event.data
      },
    },
    mounted() {
      window.addEventListener("message", this.receiveMessageFromIframe, false);
    }
  }
</script>

<style scoped lang="scss">
  iframe{
    width: 500px;
    height: 500px;
  }
</style>
