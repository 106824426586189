<template>
  <div class="media-tool" :class="showTotal? '': 'media-tool-off'">
    <div class="total-slide" @click="showTotal =! showTotal">
      <el-button style="height: 30px;" size="mini">
        <div class="slide-btn" :class="showTotal ? '': 'slide-btn-on'">
          <img src="@/assets/media/slide.png" alt="">
        </div>
      </el-button>
    </div>
    <div class="tab-container">
      <div class="tab" :class="type == 'img' ? 'tab-on': ''" @click="type = 'img'">图片</div>
      <div class="split"></div>
      <div class="tab" :class="type == 'video' ? 'tab-on': ''" @click="type = 'video'">视频</div>
      <div class="split"></div>
      <div class="tab" :class="type == 'audio' ? 'tab-on': ''" @click="type = 'audio'">音频</div>

    </div>

    <div style="transform: scale(0.8);transform-origin: top right;">
      <div class="tool-container">
        <div class="board-container"
             :class="[showBoard ? '': 'board-container-off']"
             :style="{height: type =='audio' ? (68 + audioList.length * 29 + 13 + 'px'): type =='video' ? 384 + (videoList.length > 3 ? 3: videoList.length) * 29 + 13 + 8 + 'px' : '384px'}"
        >
          <div class="top-btns">
            <div class="part" :class="type == 'audio' ? 'part-audio': ''">
              <el-upload
                  v-if="type == 'img'"
                  ref="upload"
                  :action="url"
                  :on-success="handleOnSuccess"
                  :show-file-list="false"
                  multiple
                  accept="image/*"
                  :file-list="imgList"
              >
                <div class="upload-btn">
                  <img src="@/assets/media/upload.png" alt="">
                </div>
              </el-upload>
              <el-upload
                  v-if="type == 'video'"
                  ref="upload"
                  multiple
                  :action="url"
                  :on-success="handleOnSuccess"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  accept="video/*"
                  :file-list="videoList"
              >
                <div class="upload-btn">
                  <img src="@/assets/media/upload.png" alt="">
                </div>
              </el-upload>
              <el-upload
                  v-if="type == 'audio'"
                  ref="upload"
                  :action="url"
                  :on-success="handleOnSuccess"
                  :show-file-list="false"
                  multiple
                  :before-upload="beforeAudioUpload"
                  accept="audio/*"
                  :file-list="audioList"
              >
                <div class="upload-btn">
                  <img src="@/assets/media/upload.png" alt="">
                </div>
              </el-upload>
              <el-button style="height: 52px;" v-if="type == 'img'" :loading="loading" :disabled="!imgList.length" size="mini" @click="addImg('img', 'add')">
                <div class="set-btn">
                  <img src="@/assets/media/set.png" alt="">
                </div>
              </el-button>
              <el-button style="height: 52px;" v-if="type == 'video'" :loading="loading" :disabled="!videoList.length" size="mini" @click="addVideo('video', 'add')">
                <div class="set-btn">
                  <img src="@/assets/media/set.png" alt="">
                </div>
              </el-button>
            </div>
            <div class="part" v-if="type != 'audio'" >


              <el-button style="height: 52px;" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('small')">
                <div class="scale-btn" >
                  <img src="@/assets/media/small.png" alt="">
                </div>
              </el-button>

              <el-button style="height: 52px;" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('big')">
                <div class="scale-btn">
                  <img src="@/assets/media/big.png" alt="">
                </div>
              </el-button>
            </div>
          </div>
          <div class="mid-btns" v-if="type != 'audio'">
            <div style="text-align: center">
              <el-button style="height: 40px;" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('top')">
                <div class="arrow-btn">
                  <img src="@/assets/media/arrow.png" alt="">
                </div>
              </el-button>
            </div>
            <div class="part">
              <el-button style="height: 40px;transform: rotate(-90deg)" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('left')">
                <div class="arrow-btn" >
                  <img src="@/assets/media/arrow.png" alt="">
                </div>
              </el-button>
              <el-button style="height: 40px;transform: rotate(90deg)" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('right')">
                <div class="arrow-btn" >
                  <img src="@/assets/media/arrow.png" alt="">
                </div>
              </el-button>
            </div>
            <div style="text-align: center">
              <el-button style="height: 40px;transform: rotate(180deg)" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('bottom')">
                <div class="arrow-btn">
                  <img src="@/assets/media/arrow.png" alt="">
                </div>
              </el-button>
            </div>
          </div>
          <div class="audioList" v-if="type=='audio' && audioList.length">
            <div class="list" v-if="showAudio && audioList.length">
              <div class="item" :class="curMusic.uid == item.uid ? 'item-on': ''" @click="setCurMusic(item)" v-for="(item, idx) in audioList" :key="item.uid">
                <div class="icon">
                  <img src="@/assets/media/music.png" alt="">
                  <!--                <i style="color: #F56C6C" @click="pauseBgm(item)" v-if="item.response && item.response.data && roomInfo.bgm && roomInfo.bgm.status == 'on' && roomInfo.bgm.url == item.response.data.path" :loading="loading"  class="el-icon-video-pause"></i>-->
                  <!--                <i @click="playBgm(item)" v-else class="el-icon-video-play"></i>-->
                </div>
                <div class="name">{{item.name}}</div>
                <div class="icon delete" @click="deleteBgm(idx, item.name)">
                  <img src="@/assets/media/delete.png" alt="">
                  <!--                <i class="el-icon-delete" style="color: #F56C6C"></i>-->
                </div>
              </div>
            </div>
            <!--      <el-button v-if="roomInfo.bgm && roomInfo.bgm.status == 'on'" :loading="loading" :disabled="!audioList.length" size="mini" @click="pauseBgm()">暂停</el-button>-->
            <!--      <el-button  v-else :loading="loading" :disabled="!audioList.length" size="mini" @click="playBgm()">播放</el-button>-->
          </div>

          <div class="bottom-btns" v-if="type != 'audio'" :class="!(((videoList.length && type == 'video') || (imgList.length && type == 'img'))) ? 'is-disabled': ''">
            <div class="part">
              <div class="item">
                <div class="name">X轴</div>
                <div class="btn-box">
                  <el-button style="height: 24px" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('rx2')">
                    <div class="r-btn">
                      <img src="@/assets/media/minus.png" alt="">
                    </div>
                  </el-button>
                </div>
                <img src="@/assets/media/x.png" class="cross-img" alt="">
                <div class="btn-box">
                  <el-button style="height: 24px" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('rx')">
                    <div class="r-btn">
                      <img src="@/assets/media/plus.png" alt="">
                    </div>
                  </el-button>

                </div>
              </div>

              <div class="item">
                <div class="name">Y轴</div>
                <div class="btn-box">
                  <el-button style="height: 24px" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('ry2')">
                    <div class="r-btn">
                      <img src="@/assets/media/minus.png" alt="">
                    </div>
                  </el-button>
                </div>
                <img src="@/assets/media/y.png" class="cross-img" alt="">
                <div class="btn-box">
                  <el-button style="height: 24px" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('ry')">
                    <div class="r-btn">
                      <img src="@/assets/media/plus.png" alt="">
                    </div>
                  </el-button>
                </div>
              </div>

              <div class="item">
                <div class="name">Z轴</div>
                <div class="btn-box">
                  <el-button style="height: 24px" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('rz2')">
                    <div class="r-btn">
                      <img src="@/assets/media/minus.png" alt="">
                    </div>
                  </el-button>
                </div>
                <img src="@/assets/media/z.png" class="cross-img" alt="">
                <div class="btn-box">
                  <el-button style="height: 24px" :disabled="!(((videoList.length && type == 'video') || (imgList.length && type == 'img')))" size="mini" @click="changeStatus('rz')">
                    <div class="r-btn">
                      <img src="@/assets/media/plus.png" alt="">
                    </div>
                  </el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="audioList videoList" v-if="type=='video' && videoList.length">
            <div class="list" v-if="showBoard && videoList.length">
              <div class="item" :class="curVideo.uid == item.uid ? 'item-on': ''" @click="setCurVideo(item, idx)"  v-for="(item, idx) in videoList" :key="item.uid">
                <div class="icon">
                  <img src="@/assets/media/video.png" alt="">
                  <!--                <i style="color: #F56C6C" @click="pauseBgm(item)" v-if="item.response && item.response.data && roomInfo.bgm && roomInfo.bgm.status == 'on' && roomInfo.bgm.url == item.response.data.path" :loading="loading"  class="el-icon-video-pause"></i>-->
                  <!--                <i @click="playBgm(item)" v-else class="el-icon-video-play"></i>-->
                </div>
                <div class="name">{{item.name}}</div>
                <div class="icon delete" @click="deleteVideo(idx, item)">
                  <img src="@/assets/media/delete.png" alt="">
                  <!--                <i class="el-icon-delete" style="color: #F56C6C"></i>-->
                </div>
              </div>
            </div>
            <!--      <el-button v-if="roomInfo.bgm && roomInfo.bgm.status == 'on'" :loading="loading" :disabled="!audioList.length" size="mini" @click="pauseBgm()">暂停</el-button>-->
            <!--      <el-button  v-else :loading="loading" :disabled="!audioList.length" size="mini" @click="playBgm()">播放</el-button>-->
          </div>

        </div>

        <div class="slide">
          <el-button style="height: 24px;"  size="mini" @click="showBoard =! showBoard">
            <div class="slide-btn" :class="showBoard ? '': 'slide-btn-on'">
              <img src="@/assets/media/slide.png" alt="">
            </div>
          </el-button>
        </div>


        <!--      <div class="controller" v-if="(videoList.length || imgList.length) && (type !== 'audio') && false">-->
        <!--        <el-button v-if="type == 'img'" size="mini" @click="imgPrev">上一个</el-button>-->
        <!--        <el-button v-if="type == 'img'" size="mini" @click="imgNext">下一个</el-button>-->
        <!--        <div class="direction">-->
        <!--          <el-button size="mini" @click="changeStatus('top')" class="top">上</el-button>-->
        <!--          <div style="display: flex;justify-content: space-between;">-->
        <!--            <el-button size="mini" @click="changeStatus('left')" class="left">左</el-button>-->
        <!--            <el-button size="mini" @click="changeStatus('right')" class="right">右</el-button>-->
        <!--          </div>-->
        <!--          <el-button size="mini" @click="changeStatus('bottom')" class="bottom">下</el-button>-->
        <!--        </div>-->
        <!--        <div class="cross">-->
        <!--          <el-button size="mini" @click="changeStatus('rx')" class="bottom">+X</el-button>-->
        <!--          <el-button size="mini" @click="changeStatus('rx2')" class="bottom">-X</el-button>-->
        <!--        </div>-->
        <!--        <div class="cross">-->
        <!--          <el-button size="mini" @click="changeStatus('ry')" class="bottom">+Y</el-button>-->
        <!--          <el-button size="mini" @click="changeStatus('ry2')" class="bottom">-Y</el-button>-->
        <!--        </div>-->
        <!--        <div class="cross">-->
        <!--          <el-button size="mini" @click="changeStatus('rz')" class="bottom">+Z</el-button>-->
        <!--          <el-button size="mini" @click="changeStatus('rz2')" class="bottom">-Z</el-button>-->
        <!--        </div>-->
        <!--        <div style="display: flex;justify-content: space-between">-->
        <!--          <el-button size="mini" @click="changeStatus('big')" class="bottom">放大</el-button>-->
        <!--          <el-button size="mini" @click="changeStatus('small')" class="bottom">缩小</el-button>-->
        <!--        </div>-->
        <!--        <div style="display: flex;justify-content: space-between" v-if="type == 'video'">-->
        <!--          <el-button  size="mini" @click="play" class="bottom">播放</el-button>-->
        <!--          <el-button  size="mini" @click="pause" class="bottom">暂停</el-button>-->
        <!--        </div>-->
        <!--        &lt;!&ndash;      <div style="display: flex;justify-content: space-between">&ndash;&gt;-->
        <!--        &lt;!&ndash;        <el-button  size="mini" @click="seek" class="bottom">跳转</el-button>&ndash;&gt;-->
        <!--        &lt;!&ndash;      </div>&ndash;&gt;-->
        <!--      </div>-->

      </div>
      <div class="tool-container" style="margin-top: 8px;" v-if="type == 'img'">
        <div class="img-btns">
          <div class="part">
            <el-button style="height: 52px;"  :disabled="!imgList.length" size="mini" @click="imgPrev">
              <div class="prev-btn">
                <img src="@/assets/media/prev.png" alt="">
              </div>
            </el-button>

            <el-button style="height: 52px;" :disabled="!imgList.length" size="mini" @click="imgNext">
              <div class="prev-btn" >
                <img src="@/assets/media/next.png" alt="">
              </div>
            </el-button>
          </div>
        </div>
      </div>
      <div class="tool-container" style="margin-top: 8px;" v-if="type == 'video'">
        <div class="video-btns">
          <div class="part">
            <el-button style="height: 40px;" :disabled="!videoList.length" size="mini">
              <div class="play-btn" @click="videoPrev()">
                <img src="@/assets/media/prev2.png" alt="">
              </div>
            </el-button>
            <el-button v-if="!roomInfo.video || (roomInfo.video && roomInfo.video.status == null) || (roomInfo.video && roomInfo.video.status == 'play')" style="height: 40px;" :disabled="!videoList.length" size="mini" @click="pause">
              <div class="play-btn">
                <img src="@/assets/media/pause.png" alt="">
              </div>
            </el-button>

            <el-button v-else style="height: 40px;" :disabled="!videoList.length" size="mini" @click="play">
              <div class="play-btn">
                <img src="@/assets/media/play.png" alt="">
              </div>
            </el-button>
            <el-button style="height: 40px;" :disabled="!videoList.length" size="mini">
              <div class="play-btn" @click="videoNext()">
                <img src="@/assets/media/next2.png" alt="">
              </div>
            </el-button>
          </div>
        </div>
      </div>
      <div class="tool-container" style="margin-top: 8px;" v-if="type == 'audio'">
        <div class="audio-btns">
          <div class="part">
            <el-button style="height: 40px;" :disabled="!audioList.length" size="mini">
              <div class="play-btn" @click="prevBgm()">
                <img src="@/assets/media/prev2.png" alt="">
              </div>
            </el-button>
            <el-button style="height: 40px;" :disabled="!audioList.length" size="mini">
              <div class="play-btn" @click="pauseBgm()" v-if="curMusic.response && curMusic.response.data && roomInfo.bgm && roomInfo.bgm.status == 'on' && roomInfo.bgm.url == curMusic.response.data.path">
                <img src="@/assets/media/pause.png" alt="">
              </div>
              <div class="play-btn" @click="playBgm()" v-else>
                <img src="@/assets/media/play.png" alt="">
              </div>
            </el-button>
            <el-button style="height: 40px;" :disabled="!audioList.length" size="mini">
              <div class="play-btn" @click="nextBgm()">
                <img src="@/assets/media/next2.png" alt="">
              </div>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {send} from "@/utils/websocket";

  export default {
    name: "index",
    components: {},
    props: {
      roomInfo: {
        type: Object,
        default() {
          return {}
        }
      },
      videoComplete: {
        type: Number,
        default: 0
      },
      audioComplete: {
        type: Number,
        default: 0
      }
    },
    watch: {
      videoComplete() {
        this.videoNext()
      },
      audioComplete() {
        this.nextBgm()
      },
      roomInfo: {
        handler() {

        },
        deep: true
      },
      audioList: {
        handler() {},
        deep: true
      },
      videoList: {
        handler() {},
        deep: true
      },
    },
    data() {
      return {
        loading: false,
        type: 'img',
        curIdx: 0,
        curVideoIdx: 0,
        curImg: '',
        url: 'https://shelegeji.com/admin/uploadRoom',
        imgList: [],
        videoList: [],
        audioList: [],
        showAudio: true,
        showBoard: true,
        showTotal: true,
        curMusic: {},
        curVideo: {}
      }
    },

    computed: {},
    methods: {
      setCurVideo(item, idx) {
        this.curVideo = item;
        this.curVideoIdx = idx
        this.addVideo('video-change', 'slide');
      },
      setCurMusic(item) {
        this.curMusic = item;
        this.playBgm();
      },
      prevBgm() {
        if (Object.keys(this.curMusic).length == 0) {
          this.curMusic = this.audioList[0]
          this.playBgm();
        } else {
          let idx = this.audioList.findIndex(item => item.uid == this.curMusic.uid)
           if (idx == 0) {
            this.curMusic = this.audioList[this.audioList.length - 1];
            this.playBgm();
          } else if (idx == -1) {

          } else {
            this.curMusic = this.audioList[idx - 1];
            this.playBgm();
          }
        }
      },
      nextBgm() {
        if (Object.keys(this.curMusic).length == 0) {
          this.curMusic = this.audioList[0]
          this.playBgm();
        } else {
          let idx = this.audioList.findIndex(item => item.uid == this.curMusic.uid)
          if (idx == this.audioList.length - 1) {
            this.curMusic = this.audioList[0]
            this.playBgm();
          } else if (idx == -1) {

          } else {
            this.curMusic = this.audioList[idx + 1];
            this.playBgm();
          }
        }
      },
      playBgm() {
        if (this.audioList.length) {
          let item = {};
          if (Object.keys(this.curMusic).length > 0) {
            item = this.curMusic
          } else {
            item = this.audioList[0];
            this.curMusic = this.audioList[0]
          }
          let url = item.response.data.path;
          let name = item.response.data.name;
          send({
            type: 'bgm',
            content: {
              url,
              status: 'on',
              name,
              list: this.roomInfo.bgm.list ? this.roomInfo.bgm.list: []
            }
          })
        }
      },
      pauseBgm() {
        send({
          type: 'bgm',
          content: {
            url: this.roomInfo.bgm.url,
            // url: '',
            status: 'off',
            name: this.roomInfo.bgm.name,
            list: this.roomInfo.bgm.list ? this.roomInfo.bgm.list: []
          }
        })
      },
      deleteBgm(idx, filename) {
        let arr = JSON.parse(JSON.stringify(this.audioList));
        arr.splice(idx, 1);
        this.audioList = arr;

        let status = 'off';
        let url = ''
        let name = ''
        if (this.roomInfo.bgm) {
          if (filename != this.roomInfo.bgm.name) {
            status = this.roomInfo.bgm.status
            url = this.roomInfo.bgm.url
            name = this.roomInfo.bgm.name
          }
        }
        send({
          type: 'bgm',
          content: {
            url: url,
            status: status,
            name: name,
            list: arr
          }
        })
      },
      deleteVideo(idx, item) {
        let arr = JSON.parse(JSON.stringify(this.videoList));
        arr.splice(idx, 1);
        this.videoList = arr;

        let update = {
          status: 'pause',
          url: '',
        }
        if (this.roomInfo.video) {
          if (item.response.data.path != this.roomInfo.video.url) {
            update.status = this.roomInfo.video.status
            update.url = this.roomInfo.video.url
          }
        }

        send({
          type: 'video',
          content: {
            ...this.roomInfo.video,
            ...update,
            list: arr
          }
        })
        this.$emit('addImg', {type: 'video-delete'})

      },
      beforeAudioUpload(file) {
        this.loading = true
        let flag = true;
        if (this.audioList.filter(item => item.name == file.name).length > 0) {
          flag = false
          this.$message.error('已过滤重复项')
        }
        return flag;
      },
      handleOnSuccess(response, file, fileList) {
        this.$message.success('上传成功')
        if (this.type == 'video') {
          // this.videoList = [];
          this.videoList = fileList
          if (fileList.length) {
            let arr = this.videoList.map(item => ({name: item.name, response: item.response, uid: item.uid}))
            send({
              type: 'video',
              content: {
                ...this.roomInfo.video,
                list: arr
              }
            })
          }
        }
        if (this.type == 'img') {
          this.imgList = fileList
        }
        if (this.type == 'audio') {
          this.audioList = fileList
          if (fileList.length) {
            let arr = this.audioList.map(item => ({name: item.name, response: item.response, uid: item.uid}))
            send({
              type: 'bgm',
              content: {
                url: this.roomInfo.bgm ? this.roomInfo.bgm.url: '',
                status: this.roomInfo.bgm ? this.roomInfo.bgm.status : 'off',
                name: this.roomInfo.bgm ? this.roomInfo.bgm.name: '',
                list: arr
              }
            })
          }
        }

        // if (file && file.raw && file.raw.type && file.raw.type.indexOf('video') > -1) {
        //   this.videoList = [];
        //   this.type = 'video'
        //   this.videoList = fileList
        // } else {
        //   this.type = 'img'
        //   this.imgList = fileList
        // }
        this.loading = false
      },
      beforeUpload() {
        this.loading = true
      },
      addImg(type, action) {
        this.curImg = this.imgList[this.curIdx].response.data.path
        this.$emit('addImg', {type: type || 'img', url: this.curImg, action: action})
      },
      addVideo(type, action) {
        this.curVideo = this.videoList[this.curVideoIdx]
        //this.curVideo = this.videoList[this.curVideoIdx]
        let url = this.curVideo.response.data.path
        let arr = this.videoList.map(item => ({name: item.name, response: item.response, uid: item.uid}))

        // 'storage/room/city.mp4'
        this.$emit('addImg', {type: type || 'video', url: url, action: action, list: arr})
      },
      // addVideo2(type, action) {
      //   // this.curVideo = this.videoList[this.videoList.length - 1].response.data.path
      //   // 'storage/upload/video/demo.mp4'
      //   this.$emit('addImg', {type: type || 'video', url: 'storage/upload/video/demo.mp4', action: action})
      // },
      videoPrev() {
        if (this.curVideoIdx == 0) {
          this.curVideoIdx = this.videoList.length - 1;
        } else {
          this.curVideoIdx = this.curVideoIdx - 1;
        }
        this.addVideo('video-change', 'slide');
      },
      videoNext() {
        if (this.curVideoIdx == this.videoList.length - 1) {
          this.curVideoIdx = 0;
        } else {
          this.curVideoIdx = this.curVideoIdx + 1;
        }
        this.addVideo('video-change', 'slide');
      },
      imgPrev() {
        if (this.curIdx == 0) {
          this.curIdx = this.imgList.length - 1;
        } else {
          this.curIdx = this.curIdx - 1;
        }
        this.addImg('img-change', 'slide');
      },
      imgNext() {
        if (this.curIdx == this.imgList.length - 1) {
          this.curIdx = 0;
        } else {
          this.curIdx = this.curIdx + 1;
        }
        this.addImg('img-change', 'slide');
      },
      changeStatus(type) {
        if (this.type == 'img') {
          this.addImg('img-change', type);
        } else if (this.type == 'video') {
          this.addVideo('video-change', type);
        }
      },
      play() {
        this.addVideo('video-change', 'play');
      },
      pause() {
        this.addVideo('video-change', 'pause');
        setTimeout(() => {
          console.log(this.roomInfo);

        }, 1000)
      },
      seek() {
        this.addVideo('video-change', 'seek');
      },
    },
    mounted() {
      if (this.roomInfo.video && this.roomInfo.video.list) {
        this.type = 'video'
        // this.videoList = [
        //   {response: {data: {path: this.roomInfo.video.url}}}
        // ]
        this.videoList = this.roomInfo.video.list;
        this.videoList.forEach((item, idx) => {
          if (item.response.data.path == this.roomInfo.video.url) {
            this.curVideo = item;
            this.curVideoIdx = idx
          }
        })
      }
      if (this.roomInfo.img && this.roomInfo.img.url) {
        this.type = 'img'
        this.imgList = [
          {response: {data: {path: this.roomInfo.img.url}}}
        ]
      }
      if (this.roomInfo.bgm && this.roomInfo.bgm.list) {
        this.audioList = this.roomInfo.bgm.list
        this.audioList.forEach((item) => {
          if (item.response.data.path == this.roomInfo.bgm.url) {
            this.curMusic = item;
          }
        })
      }
    },
  }
</script>

<style scoped lang="scss">
  $anitime: 0.4s ease;
  .media-tool{
    position: fixed;
    top: 60px;
    right: 8px;
    z-index: 5;
    width: 147px;
    transition: $anitime;

    /deep/ .el-button{
      width: unset;
      height: unset;
      background: none;
      border-radius: 0px;
      padding:0px;
      outline: none;
      border: none!important;
      margin-bottom: 0px;
      &:before{
        display: none;
      }
      .el-icon-loading{
        position: absolute;
        left: 1px;
        top: 1px;
      }
      span{
        margin-left: 0px;
      }
    }
    /deep/ .is-disabled{
      opacity: 0.3;
    }

    .total-slide{
      width: 30px;
      height: 30px;
      text-align: center;
      position: fixed;
      top: 60px;
      right: 0px;
      border-radius: 6px;
      background: #fff;
      .slide-btn{
        width: 30px;
        height: 30px;
        transition: $anitime;
        transform: rotate(90deg);

        img{
          width: 24px;
          height: 24px;
          @include center();
        }
      }
      .slide-btn-on{
        transform: rotate(-90deg);
      }
    }

    .tab-container{
      width: 147px;
      height: 30px;
      background: #FFFFFF;
      border-radius: 6px;
      margin-bottom: 8px;
      .tab{
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        margin: 0 5.5px;
        display: inline-block;
        float: left;
        transition: $anitime;
      }
      .tab-on{
        color: #FF8E26;
        font-weight: bold;
      }
      .split{
        width: 0.5px;
        height: 10px;
        background: rgba(#333333, 0.7);
        float: left;
        position: relative;
        top: 10px;
      }
    }
    .tool-container{
      background: rgba(255,255,255,0.25);
      border-radius: 6px 6px 6px 6px;
      .upload-btn, .set-btn, .scale-btn{
        width: 40px;
        height: 52px;
        img{
          width: 40px;
          height: 52px;
        }
      }
      .board-container{
        width: 100%;
        height: 384px;
        transition: $anitime;
        overflow: hidden;
      }
      .board-container-off{
        min-height: 0px!important;
        height: 0px!important;
        opacity: 0;
        transform: translateY(-10px);
      }
      .top-btns{
        width: 100%;
        .part{
          margin: 0 auto;
          width: 92px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          padding-top: 8px;
        }
        .part-audio{
          width: 40px;
        }
      }
      .mid-btns{
        width: 100%;
        padding-top: 4px;
        .arrow-btn{
          width: 40px;
          height: 40px;
          img{
            width: 40px;
            height: 40px;
          }
        }
        .part{
          width: 120px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          margin-top: -4px;

        }
      }
      .bottom-btns{
        width: 100%;
        padding-top: 4px;
        .part{
          width: 127px;
          background: rgba(255,255,255,0.75);
          border-radius: 6px 6px 6px 6px;
          box-sizing: border-box;
          padding-top: 8px;
          padding-bottom: 8px;
          margin: 0 auto;
          .item{
            margin-bottom: 16px;
            @include clear;
            &:last-child{
              margin-bottom: 0px;
            }
            .name{
              float: left;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              color: #333;
              margin-left: 8px;
              margin-right: 8px;
            }
            .btn-box{
              float: left;
              height: 24px;
            }
            .cross-img{
              margin-left: 8px;
              margin-right: 8px;
              width: 20px;height: 20px;float: left;
              position: relative;
              top: 2px;
            }
            .r-btn{
              width: 24px;
              height: 24px;
              img{
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .slide{
        width: 100%;
        text-align: center;
        padding-top: 4px;
        .slide-btn{
          width: 24px;
          height: 24px;
          transition: $anitime;
          img{
            width: 24px;
            height: 24px;
          }
        }
        .slide-btn-on{
          transform: rotate(180deg);
        }
      }
      .img-btns{
        width: 100%;
        .part{
          margin: 0 auto;
          width: 104px;
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
          .prev-btn{
            width: 46px;
            height: 52px;
            img{
              width: 46px;
              height: 52px;
            }
          }
          .play-btn{
            width: 40px;
            height: 40px;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }


      }
      .video-btns{
        width: 100%;
        .part{
          width: calc(147px - 16px);
          margin-left: 8px;
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          .play-btn{
            width: 40px;
            height: 40px;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      .audio-btns{
        width: 100%;
        .part{
          width: calc(147px - 16px);
          margin-left: 8px;
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
          .play-btn{
            width: 40px;
            height: 40px;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    button{
      margin-bottom: 12px;
      margin-left: 0px;
      padding: 8px;
    }
    .controller{
      width: 90px;
      float: right;
    }
    .audioList{
      width: 100%;
      @include clear;
      .list{
        width: calc(100% - 20px);
        margin-left: 10px;
        @include clear;
        box-sizing: border-box;
        background: rgba(255,255,255,0.75);
        border-radius: 6px 6px 6px 6px;
        padding-top: 13px;
      }
      .item{
        width: 100%;
        @include clear;
        float: right;
        margin-bottom: 13px;
        .icon{
          float: left;
          width: 13px;
          height: 16px;
          text-align: center;
          margin-left: 4px;
          margin-right: 4px;
          line-height: 16px;
          color: #409EFF;

          img{
            width: 13px;
            height: 13px;
            position: relative;
            top: 1px;
          }
        }
        .name{
          float: left;
          width: calc(100% - 42px);
          padding-right: 0px;
          box-sizing: border-box;
          text-align: left;
          @include elli;
          height: 16px;
          line-height: 16px;
          font-size: 13px;
          color: #333;
          transition: $anitime;
        }

      }
      .item-on{
        .name{
          color: #FF8E26;
        }
      }
    }
    .videoList{
      max-height: 100px;
      overflow-y: auto;
      margin-top: 8px;
    }
    .direction{
      width: 90px;
      margin-bottom: 12px;
      .top, .bottom{
        margin: 0 auto;
      }
      .left, .right{
        margin-bottom: 0px;
      }
      button{
        display: block;
        padding: 8px;
      }
    }
    .cross{
      margin-left: 20px;
      width: 70px;
      display: flex;
      justify-content: space-between;
    }
    /deep/ .el-radio-button--mini{
      .el-radio-button__inner{
        padding: 7px 4px;
      }
    }
  }
  .media-tool-off{
    right: -147px;
  }
</style>
