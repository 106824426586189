<template>
  <div class="media-tool">
    <el-upload
      ref="upload"
      :action="url"
      :on-success="handleOnSuccess"
      :before-upload="beforeUpload"
      :show-file-list="false"
      accept="audio/*"
      :file-list="audioList"
      >
      <el-button size="mini" type="primary">上传音频</el-button>
    </el-upload>
    <el-button v-if="roomInfo.bgm && roomInfo.bgm.status == 'on'" :loading="loading" :disabled="!audioList.length" size="mini" @click="pauseBgm()">暂停</el-button>
    <el-button  v-else :loading="loading" :disabled="!audioList.length" size="mini" @click="playBgm()">播放</el-button>

  </div>
</template>
<script>
  import {send} from "@/utils/websocket";

  export default {
    name: "index",
    components: {},
    props: {
      roomInfo: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    watch: {
      roomInfo: {
        handler() {

        },
        deep: true
      }
    },
    data() {
      return {
        loading: false,
        url: 'https://shelegeji.com/admin/uploadRoom',
        urlPrefix: 'https://shelegeji.com/',
        audioList: [],
      }
    },

    computed: {},
    methods: {
      handleOnSuccess(response, file, fileList) {
        this.$message.success('上传成功')
        this.audioList = fileList
        this.loading = false
      },
      beforeUpload() {
        this.loading = true
      },
      playBgm() {
        if (this.audioList.length) {
          let item = this.audioList[this.audioList.length - 1];
          let url = item.response.data.path;
          let name = item.response.data.name;
          send({
            type: 'bgm',
            content: {
              url,
              status: 'on',
              name
            }
          })
        }
      },
      pauseBgm() {
        send({
          type: 'bgm',
          content: {
            url: this.roomInfo.bgm.url,
            status: 'off',
            name: this.roomInfo.bgm.name
          }
        })
      }
    },
    mounted() {
      if (this.roomInfo.bgm && this.roomInfo.bgm.url) {
        this.audioList = [
          {
            name: this.roomInfo.bgm.name,
            response: {data: {path: this.roomInfo.bgm.url}}
          }
        ]
      }

    },
  }
</script>

<style scoped lang="scss">
  .media-tool{
    position: fixed;
    top: 100px;
    left: 0px;
    z-index: 5;
    width: 90px;
    text-align: left;
    button{
      margin-bottom: 12px;
      margin-left: 0px;
      padding: 8px;
    }
    .direction{
      width: 90px;
      margin-bottom: 12px;
      .top, .bottom{
        margin: 0 auto;
      }
      .left, .right{
        margin-bottom: 0px;
      }
      button{
        display: block;
        padding: 8px;
      }
    }
    .cross{
      margin-left: 20px;
      width: 70px;
      display: flex;
      justify-content: space-between;
    }
    /deep/ .el-radio-button--mini{
      .el-radio-button__inner{
        padding: 7px 4px;
      }
    }
  }
</style>
