const demo = {
    namespaced: true,
    state: {
        count: 0,
        age: 1
    },
    getters: {
        getAge(state) {
            return state.age;
        }
    },
    mutations: {
        increment(state) {
            state.count++;
        }
    },
    actions: {
        increment({ commit }) {
            commit("increment");
        }
    }
};

export default demo;
