let webSocket = null;
let socketOpen = false;

// 发送消息
export const send = (message) => {
  if (!webSocket) {
    return;
  }
  if (socketOpen) {
    try {
      message.uid = localStorage.getItem('uid') || ''
      message.video = localStorage.getItem('video') || ''
      message.uname = localStorage.getItem('uname') || ''
      message.avatar = localStorage.getItem('avatar') || ''
      message.scene = localStorage.getItem('scene') || ''
      message.room = localStorage.getItem('room') || ''
      message.host = localStorage.getItem('host') || ''
      webSocket.send(JSON.stringify(message))
    } catch (e) {
      console.log(message, "JSON解析错误");
    }
  }
}

// 发送消息
export const bindUser = (user) => {
  if (socketOpen) {
    webSocket.send(JSON.stringify({
      type: 'bind',
      uid: user,
      uname: localStorage.getItem('uname') || '',
      scene: localStorage.getItem('scene') || '',
      room: localStorage.getItem('room') || '',
      host: localStorage.getItem('host') || '',
    }))
  }
}

// 发送消息
export const close = () => {
  if (socketOpen) {
    webSocket.close();
  }
}

// 初始化websocket
export const contactSocket = (user, funcs) => {
  // https://192.168.1.167:2345/
  // https://shelegeji.com:2345/
  // webSocket = new WebSocket("wss://192.168.50.246:2345");
  // webSocket = new WebSocket("wss://127.0.0.1:2345");
  webSocket = new WebSocket("wss://shelegeji.com:2345");
  webSocket.onopen = function () {
    socketOpen = true
    bindUser(user)
  };
  webSocket.onmessage = function (evt) {
    var _msg = evt.data;

    try {
      let msg = JSON.parse(_msg);
      switch (msg.type) {
        case 'broadcast':
          funcs.onBroadcast && funcs.onBroadcast(msg);
          break;
        case 'private':
          funcs.onPrivate && funcs.onPrivate(msg);
          break;
        case 'msg':
          funcs.onMsg && funcs.onMsg(msg);
          break;
        case 'bind':
          funcs.onBind && funcs.onBind(msg);
          break;
        case 'location':
          funcs.onLocation && funcs.onLocation(msg);
          break;
        case 'init':
          funcs.onInit && funcs.onInit(msg);
          break;
        case 'leave':
          funcs.onLeave && funcs.onLeave(msg);
          break;
        case 'floor':
          funcs.onFloor && funcs.onFloor(msg);
          break;
        case 'room':
          funcs.onRoom && funcs.onRoom(msg);
          break;
        case 'offer':
          funcs.onOffer && funcs.onOffer(msg);
          break;
        case 'answer':
          funcs.onAnswer && funcs.onAnswer(msg);
          break;
        case 'candidate':
          funcs.onCandidate && funcs.onCandidate(msg);
          break;
        case 'join':
          funcs.onJoin && funcs.onJoin(msg);
          break;
      }
    } catch (e) {
      console.log(e, 'socket信息错误')
    }
    // console.log("接受消息：" + received_msg);
  };
  webSocket.onclose = function () {
    funcs.onClose && funcs.onClose();
    webSocket = null;
    socketOpen = false;
    console.log(">>>>>>>连接关闭！");
  };
  webSocket.onerror = function (res) {
    console.log(res, ">>>>>>>连接异常！");
  };
}
