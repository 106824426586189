import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from "@/store";
import '@/assets/iconfont/iconfont.css'
import VueClipboard from 'vue-clipboard2';

import { Message, MessageBox,Dropdown,
  DropdownMenu,
  DropdownItem,Input, Notification, Button, Drawer, Col, Row, Upload, Radio, RadioGroup, RadioButton, Image, Dialog} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.prototype.$notify = Notification ;
Vue.use(Button);
Vue.use(Input);
Vue.use(Drawer);
Vue.use(Col);
Vue.use(Row);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Image);
Vue.use(VueClipboard);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.config.productionTip = false

new Vue({
  store,
  router, // router: router简写
  render: h => h(App),
}).$mount('#app')
