import http from '@/axios/http';

export function getAvatarList(params){
    return http.get('app/avatar/list', params)
}

export function addAvatar(params){
    return http.post('app/avatar/add', params)
}

export function addChat(params) {
    // return http.post('https://chat.thy-note.top/app/api/chat', params)
    return http.post('https://chat.chatthynoteaaaa.asia/app/api/chat', params)

}

export function genImg(params) {
    // return http.post('https://chat.thy-note.top/app/api/img', params)
    return http.post('https://chat.chatthynoteaaaa.asia/app/api/img', params)

}
