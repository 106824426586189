<template>
  <div class="media-tool">
      <el-button size="mini" @click="addFake">添加</el-button>
      <el-button size="mini" @click="removeFake">移除</el-button>
  </div>
</template>
<script>
  export default {
    name: "index",
    components: {},
    props: {
      roomInfo: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    data() {
      return {

      }
    },

    computed: {},
    methods: {
      addFake() {
        this.$emit('addFake', {type: 'add-fake'})
      },
      removeFake() {
        this.$emit('addFake', {type: 'remove-fake'})
      },
    },
    mounted() {
    },
  }
</script>

<style scoped lang="scss">
  .media-tool{
    position: fixed;
    top: 100px;
    right: 0px;
    z-index: 999;
    width: 90px;
    text-align: right;
    button{
      margin-bottom: 12px;
      margin-left: 0px;
      padding: 8px;
    }
  }
</style>
