import Vue from "vue";
import VueRouter from "vue-router";
import Demo from "@/pages/demo";
import Index from "@/pages/index";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "index",
        component: Layout,
        children: [
            {
                path: "/index",
                name: "index",
                component: Index
            },
            {
                path: "/demo",
                name: "demo",
                component: Demo
            },
        ]
    }
];

export default new VueRouter({
    routes,
    mode: 'hash'
});
