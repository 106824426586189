<template>
  <div class="room-container">
    <div @click="showList = !showList" class="main-icon" :class="showList ? 'main-icon-on' : ''">
      <div class="icon">
        <i class="iconfont icon-kehuqunzu"></i>
      </div>
      <div class="count">
        <span>{{Object.keys(this.userMap).length}}</span>
      </div>
    </div>
    <div class="list _scroll_meeting" :class="showList ? 'list-on' : ''">
      <div class="member-item" v-if="Object.keys(hostItem).length">
        <div class="avatar">
          <img class="avatar-icon" v-if="hostItem.avatar && !hostItem.video" :src="hostItem.avatar.replace('avatar', 'user/' + hostItem.uid).replace('_6', '_icon')" alt="">
          <img class="avatar-icon" v-if="hostItem.video" :src="hostItem.video" alt="">
          <div v-if="uid == host" class="audio-controller audio-controller-host" @click="handleMuteAll">
            <i v-if="!muteAll" class="iconfont icon-audio-fill"></i>
            <i v-if="muteAll" class="iconfont icon-audiostatic"></i>
            <span>ALL</span>
          </div>
        </div>
        <div :id="'member' + hostItem.uid" class="avatar-video"></div>
        <div class="name">
          {{hostItem.uname}}
        </div>
      </div>
      <div class="split"></div>
      <div class="member-item" @click="setCurItem(item.uid)" :class="muteList.indexOf(item.uid) > -1 ? 'muted': ''" v-for="item in list" :key="item.uid">
        <div class="avatar" >
          <div v-if="item.avatar">
            <img class="avatar-icon" v-if="item.avatar && !item.video"  :src="item.avatar.replace('avatar', 'user/' + item.uid).replace('_6', '_icon')" alt="">
            <img class="avatar-icon" v-if="item.video" :src="item.video" alt="">
            <div class="audio-controller" @click="handleMute(item)" v-if="muteList.indexOf(item.uid) > -1 || uid == host">
              <i v-if="muteList.indexOf(item.uid) == -1" class="iconfont icon-audio-fill"></i>
              <i v-if="muteList.indexOf(item.uid) > -1" class="iconfont icon-audiostatic"></i>
            </div>
            <div class="host-controller" v-if="uid == host">
              <i class="iconfont icon-qizhi-" slot="reference"  @click="handleSetHost(item)"></i>
            </div>
          </div>
        </div>
        <div :id="'member' + item.uid" class="avatar-video"></div>
        <div class="name">
          {{item.uname}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { send }  from '@/utils/websocket'
  import {orderBy} from 'lodash'
  export default {
    name: "index",
    components: {},
    data() {
      return {
        visible: false,
        list: [],
        host: "",
        hostItem: {},
        muteList: [],
        muteAll: false,
        uid: localStorage.getItem('uid'),
        curItem: '',
        showList: true
      }
    },
    props: {
      room: {
        type: String,
        default: ''
      },
      userMap: {
        type: Object,
        default() {
          return {}
        }
      },
      roomInfo: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    watch: {
      userMap: {
        handler(nv) {
          const arr = Object.values(nv).filter(item => item.uid != this.host);
          this.list = orderBy(arr, ['uname'], ['asc']);
          this.hostItem = Object.values(this.userMap).filter(item => item.uid == this.host)[0] || {};
        },
        deep: true,
        immediate: true
      },
      roomInfo: {
        handler(nv) {
          this.host = nv.host;
          this.muteList = nv.mute_list || []
          const arr = Object.values(this.userMap).filter(item => item.uid != this.host);
          this.hostItem = Object.values(this.userMap).filter(item => item.uid == this.host)[0] || {};
          this.list = orderBy(arr, ['uname'], ['asc']);
        },
        deep: true,
        immediate: true
      },
      muteList: {
        handler(nv) {
          if (this.uid != this.host) {
            if (nv.indexOf(this.uid) > -1) {
              this.$emit('muteAudio', true)
            } else {
              this.$emit('muteAudio', false)
            }
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      handleMuteAll() {
        this.muteAll = !this.muteAll
        this.muteList = this.muteAll? this.list.map(item => item.uid) : [];

        let obj = {
          type: 'mute',
          content: this.muteList,
        }
        send(obj)
      },
      handleMute(item) {
        if (this.uid != this.host) {
          return;
        }
        let idx = this.muteList.indexOf(item.uid);
        if (idx == -1) {
          this.muteList.push(item.uid)
        } else {
          this.muteList.splice(idx, 1)
        }

        let obj = {
          type: 'mute',
          content: this.muteList,
        }
        send(obj)
      },
      handleSetHost(item) {
        this.$confirm('确认设置' + item.uname +'为房主吗？?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let obj = {
            type: 'host',
            content: item.uid,
          }
          send(obj)
        }).catch(() => {

        });

      },
      setCurItem(item) {
        if (this.uid == this.host) {
          this.curItem = item.uid;
        }
      }
    },
    mounted() {

    },
  }
</script>
<style lang="scss">
  .el-popover{
    p{
      margin: 5px 0px;
      font-size: 12px;
    }
  }
  .el-message-box__wrapper{
    .el-message-box{
      width: 300px;
    }
  }
</style>
<style scoped lang="scss">
  .room-container{
    position: fixed;
    width: 52px;
    height: 52px;
    top: 10px;
    left: 10px;
    .main-icon{
      width: 52px;
      height: 52px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      font-size: 14px;
      color: #D3741C;
      text-align: center;
      transition: 0.3s ease;
      .icon{
        width: 100%;
        height: 24px;
        text-align: center;
        padding-top: 6px;
        i{
          transition: 0.3s ease;
          font-size: 24px;
        }
      }
      .count{
        width: 100%;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        span{
          transition: 0.3s ease;
        }
      }
    }
    .main-icon-on{
      background: #D3CDC8;
      color: #999;
    }
    .list{
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 52px;
      background: rgba(#999,0.7);
      border-radius: 52px;
      box-sizing: border-box;
      padding-top: 60px;
      padding-bottom: 8px;
      max-height: 60vh;
      overflow-x: scroll!important;
      transition: 0.5s ease;
      transform: translateY(10px);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      .split{
        width: 44px;
        height: 1px;
        background: #FFFFFF;
        margin-bottom: 8px;
        margin-left: 4px;
      }
      .member-item{
        margin-bottom: 8px;
        position: relative;
        .avatar-video{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 4px;
          overflow: hidden;
          /deep/ .video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 10;
            transform: rotate3d(0, 1, 0, 180deg);
          }
        }
        .avatar{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 4px;
          margin-bottom: 4px;
          background: #fff;
          position: relative;

          .audio-controller{
            width: 20px;
            height: 20px;
            background: #FFF1E4;
            border-radius: 50%;
            position: absolute;
            left: 30px;
            bottom: 0px;
            z-index: 5;
            text-align: center;
            line-height: 20px;
            i{
              font-size: 16px;
              color: #D3741C;
            }
          }
          .host-controller{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: absolute;
            left: 32px;
            top: -5px;
            z-index: 5;
            text-align: right;
            line-height: 20px;
            i{
              font-size: 18px;
              color: #EBAF13;
            }
          }
          .audio-controller-host{
            width: 22px;
            height: 22px;
            line-height: 12px;
            left: 28px;
            i{
              font-size: 12px;
            }
            span{
              width: 100%;
              display: block;
              font-size: 12px;
              color: #D3741C;
              transform: scale(0.5);
              transform-origin: top;
            }
          }
          .avatar-icon{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-position: center;
            object-fit: cover;
          }
        }
        .name{
          width: 100%;
          text-align: center;
          font-size: 12px;
          color: #fff;
          line-height: 12px;
        }
      }
    }
    .list-on{
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      pointer-events: unset!important;
    }
  }
</style>
