<template>
  <div id="app">
<!--    <div style="position: fixed;top: 0;left: 0;zIndex: 99999;color: #fff;" @click="getVideoCapture">-->
<!--      v4.5-->
<!--    </div>-->

    <div class="bgm-player" v-if="roomInfo.bgm && showBgm">
      <audio style="display: block!important;"  muted :src="mediaUrl + '/' + roomInfo.bgm.url" id="bgm"  >
      </audio>
    </div>
    <div class="video-modal-mask" :class="showVideo ? 'video-modal-mask-show': ''">
      <div class="video-modal">
        <div class="video-wrapper">
          <img v-if="photo" class="thumb" :src="photo" alt="">
          <img class="video-mask-png" v-if="!photo" src="./assets/video-mask.png" alt="">
        </div>
        <div class="take-btn">
          <img @click="takePhoto('take')" src="./assets/take.png" alt="">
        </div>
      </div>
    </div>
    <div class="user-mask" v-show="(!bound || showModal) && renderUi">
      <div class="user-modal _scroll_meeting">
        <div class="welcome-tip">
          欢迎进入<br/>{{title}}的元宇宙
        </div>
        <div class="name-tip">1、先给自己起个名字</div>
        <div class="name-input">
          <el-input type="text" placeholder="输入用户名" :maxlength="10" v-model="uname" />
          <i class="el-icon-refresh" @click="genUname"></i>
        </div>
        <div class="choose-tip">2、再给自己选个身体</div>
        <div class="swiper" id="user-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :class="avatar == item.avatar ? 'avatar-on' : ''" v-for="item in imgList" :key="item.id" @click="avatarChange(item)">
              <img :src="item.avatar" class="swiper-lazy"/>
            </div>
          </div>
        </div>
        <div class="ai-container">
          <div class="choose-tip">3、拍摄你的头像照片</div>
          <div class="video-container">
            <div class="video-mask" @click="takePhoto('camera')">
              <img src="./assets/video-icon.png" class="photo-avatar" alt="">
            </div>
            <img v-if="photo"  @click="takePhoto('camera')" class="thumb" :src="photo" alt="">
            <div class="video-temp"></div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;width: calc(100% - 32px);margin-left: 16px;" v-if="!photo">
          <el-button v-if="!showModal && !room" class="joinvr create-room" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleLogin(true)">创建房间</el-button>
          <span style="width: 20px" v-if="!showModal && !room"></span>
          <el-button v-if="!showModal" class="joinvr" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleLogin(false)">登录</el-button>
        </div>
        <div style="display: flex;justify-content: space-between;width: calc(100% - 32px);margin-left: 16px;" v-if="photo && !showModal">
          <div class="skip" size="mini" type="primary" @click="handleLogin(true)">跳过</div>
          <span style="width: 20px" v-if="!room"></span>
          <el-button class="joinvr next-tip" size="mini" type="primary" :loading="nextLoading"  @click="handleNext()">下一步</el-button>
        </div>

        <div style="display: flex;justify-content: space-between;width: calc(100% - 32px);margin-left: 16px;" v-if="photo && showModal">
          <div class="skip" size="mini" type="primary" @click="handleUpdate()">修改</div>
          <span style="width: 20px"></span>
          <el-button class="joinvr next-tip" size="mini" type="primary" :loading="nextLoading"  @click="handleNext()">下一步</el-button>
          <span style="width: 20px"></span>
          <div class="skip" size="mini" type="primary" @click="showModal = false">关闭</div>
        </div>
        <div v-if="!photo" style="display: flex;justify-content: space-between;width: calc(100% - 32px);margin-left: 16px;margin-bottom: 10px;">
          <el-button style="width: 47%;background-color: #eee;color: #333;margin: 0"  v-if="showModal" class="joinvr" size="mini" type="primary" @click="showModal = false">关闭</el-button>
          <el-button style="width: 47%;margin: 0" v-if="showModal" class="joinvr" size="mini" type="primary" :disabled="!avatar || !uname" @click="handleUpdate">修改</el-button>
        </div>
        <div v-if="!photo">
          <el-button v-if="showModal && !room" class="joinvr create-room" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleLogin(true, true)">创建房间</el-button>
        </div>
      </div>
      <div class="user-modal user-modal2" v-if="showNext && uid && avatar_name">
        <div class="welcome-tip">
          欢迎进入<br/>{{title}}的元宇宙
        </div>
        <div class="name-tip">模型预览</div>
        <div class="img-box" :class="showNext ? 'img-box-ani': ''">
          <div class="preview-box"  @touchstart="onTouchStart($event)"
               @touchmove="onTouchMove($event)">
            <div class="mark-shape"></div>
          </div>
          <div class="img-main">
            <img :src="userPrefix+ uid + '/' + avatar_name + '/_'+previewIdx+'.png'" alt="" >
          </div>
          <img class="img-mask" :src="userPrefix+ uid + '/' + avatar_name + '/_'+previewIdx+'.png'" alt="" >
          <img class="pointer" src="./assets/pointer.png" alt="">
        </div>
        <div style="margin-bottom: 10px" class="back-prev" size="mini" type="primary"  @click="showNext = false">返回上一步</div>
        <div v-if="!showModal" style="display: flex;justify-content: space-between;width: calc(100% - 32px);margin-left: 16px;">
          <el-button v-if="!room" class="joinvr create-room" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleLogin(true, true)">创建房间</el-button>
          <span style="width: 20px" v-if="!room"></span>
          <el-button  class="joinvr" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleLogin(false, true)">登录</el-button>
        </div>
        <div v-if="showModal" style="display: flex;justify-content: space-between;width: calc(100% - 32px);margin-left: 16px;">
          <el-button v-if="!room" class="joinvr create-room" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleLogin(true, true)">创建房间</el-button>
          <span style="width: 20px" v-if=" !room"></span>
          <el-button class="joinvr" size="mini" type="primary" :disabled="!avatar || !uname" :loading="connectLoading" @click="handleUpdate()">修改</el-button>
        </div>
      </div>
    </div>
    <div v-show="renderUi" class="message-box">
      <el-button class="expand" @click="handleShowModal">
        <img v-if="icon" :src="icon" alt="">
      </el-button>
      <el-input type="text" placeholder="输入信息" v-model="msg"></el-input>
      <el-dropdown @command="handleMsgType" class="menu" trigger="click">
        <el-button class="target">
          <img :src="msgType.avatar" :class="msgType.uid" :alt="msgType.uid">
        </el-button>
        <el-dropdown-menu slot="dropdown">

          <el-dropdown-item :command="item.uid" v-for="item in userMap" v-if="item.uid != uid" :key="item.uid">
            <div class="menu-icon">
              <img :src="item.avatar" >
            </div>
            <span class="menu-name">{{item.uname}}</span>
          </el-dropdown-item>

          <el-dropdown-item command="ai">
            <img src="./assets/ai.png" alt="" class="menu-icon ai">
            <span class="menu-name">小安助手</span>
          </el-dropdown-item>

          <el-dropdown-item command="ai_pic">
            <img src="./assets/ai_pic.png" alt="" class="menu-icon ai_pic">
            <span class="menu-name">小安绘图</span>
          </el-dropdown-item>

          <el-dropdown-item command="public">
            <img src="./assets/public.png" alt="" class="menu-icon">
            <span class="menu-name">公共频道</span>
          </el-dropdown-item>


        </el-dropdown-menu>
      </el-dropdown>
      <el-button @click="handleSend(msgType.uid == 'public' ? 'broadcast': 'private')" :disabled="!msg">
        <i class="el-icon-s-promotion"></i>
      </el-button>
    </div>
    <div v-show="renderUi" class="message-list _scroll_meeting" ref="scrollDiv" >
      <div class="message-item" v-for="(item, idx) in messageList" :key="idx">
        <span class="text" v-if="item.type !== 'img'">{{item.uname}}: <span class="text-content" :class="item.target && item.target == uid ? 'pink': ''">{{item.content}}</span></span>
        <span class="text" v-if="item.type == 'img'">
           {{item.uname}}: <el-image
             v-if="item.type == 'img'"
             style="width: 120px; height: 120px;top: 4px;"
             :src="item.content"
             :preview-src-list="[item.content]">
        </el-image>
        </span>
        <span v-if="item.type == 'chatgpt'" class="copy" v-clipboard:copy="item.content" v-clipboard:success="onCopy">复制</span>
        <span class="send-tips" v-if="item.target && item.target != uid">
          - {{item.target == uid ? '': '发送至' + item.targetUname}}
        </span>
      </div>
    </div>
    <div v-if="Object.keys(remoteUsers).length && renderUi">
      <AudioPlayer v-for="item in remoteUsers" :key="item.uid" :audioTrack="item.audioTrack" :text="item.uid"></AudioPlayer>
    </div>
    <div class="share" @click="handleShare">
      <img src="./assets/share.png" alt="">
    </div>
    <div class="container">
      <div v-if="renderUi" class="count">
        在线人数: {{+userCount + 1}}
<!--        <span v-if="room">房间号: {{room}}</span>-->
<!--        <span v-if="uid">uid: {{uid}}</span>-->
<!--        <span v-if="Object.keys(roomInfo)">host: {{roomInfo.host}}</span>-->
      </div>
      <div v-if="renderUi && !room" class="groupMember _scroll_meeting">
        <div v-for="item in groupMember" :key="item.uid">
          <div class="item">
            <img :src="item.avatar.replace('_6', '_icon')" alt="">
            <div class="name" :title="item.uid">{{item.uname}}</div>
          </div>
        </div>
      </div>
      <iframe v-if="scene && !debug" :src="scene" ref="iframeRef" frameborder="0"></iframe>
      <iframe v-if="debug" src='office/index.html' ref="iframeRef" frameborder="0"></iframe>
    <!--  <iframe v-if="debug" src='render-jingkai/index.html' ref="iframeRef" frameborder="0"></iframe>-->
    <!--  <iframe v-if="debug" src='meishuguan/index.html' ref="iframeRef" frameborder="0"></iframe>-->
    <!--  <iframe v-if="debug" src='molly/index.html' ref="iframeRef" frameborder="0"></iframe>-->
    </div>
    <div class="back back-action" v-if="back && renderUi && action">
      <img :src="back" alt="">
    </div>
    <div class="back" v-if="back && renderUi && !action">
      <img :src="back" alt="">
    </div>
    <ActionTool v-if="showAction" @handleActions="handleActions" :actioning="actioning"></ActionTool>
    <MediaTool @addImg="addImg" v-if="room && (roomInfo.host == uid)" :videoComplete="videoComplete" :audioComplete="audioComplete" :roomInfo="roomInfo"></MediaTool>
<!--    <BgmTool v-if="room && (roomInfo.host == uid)" :roomInfo="roomInfo"></BgmTool>-->
    <RoomController :userMap="userMap" :roomInfo="roomInfo" :room="room" @muteAudio="muteAudio"></RoomController>
    <DemoController v-if="false" :roomInfo="roomInfo" @addFake="addFake"></DemoController>
    <el-button class="media-show-btn" v-if="room && (roomInfo.host != uid)" @click="showMediaDialog" icon="el-icon-picture-outline" circle></el-button>
    <el-dialog
      v-if="room && (roomInfo.host != uid)"
      :visible.sync="mediaDialog"
      width="100%"
      fullscreen
      :destroy-on-close="true"
    >
      <el-radio-group v-model="mediaType" size="mini">
        <el-radio-button label="img">图片</el-radio-button>
        <el-radio-button label="video">视频</el-radio-button>
      </el-radio-group>
      <div class="mediaImg" v-if="mediaType == 'img'">
        <img  :src="mediaImg" alt="">
      </div>
      <div class="mediaVideo" id="mediaVideo" v-if="mediaType == 'video'">
        <video style="width: 100%;" controls autoplay="true" playsinline="true" x-webkit-airplay="true" x5-video-player-fullscreen="true" :src="mediaVideo"></video>
<!--        <video autoplay="true" playsinline="true" x-webkit-airplay="true" x5-video-player-fullscreen="true" :src="mediaVideo"></video>-->
      </div>
    </el-dialog>
  </div>
</template>
<script>
let stream = null;
const imgUrl = 'https://anjing.shelegeji.com';
const mediaUrl = 'https://shelegeji.com';
let localStream;
let rtcMap = {};
const configuration = {
  iceTransportPolicy: 'relay',
  iceServers: [
    {urls: 'stun:149.248.5.190:3478'},
    {
      urls: 'turn:149.248.5.190:3478?transport=tcp',
      "username":"thy",
      "credential":"110110",
      "credentialType": "password"
    }
  ],
};
import Swiper from 'swiper'
import 'swiper/swiper.min.css';
import AudioPlayer from '@/components/AudioPlayer'
import RoomController from '@/components/RoomController'
import DemoController from '@/components/DemoController'
import MediaTool from '@/components/MediaTool'
import BgmTool from '@/components/BgmTool'
import ActionTool from '@/components/ActionTool'
import AgoraRTC from "agora-rtc-sdk-ng"
import { contactSocket, send, close, bindUser }  from '@/utils/websocket'
import { md5 } from '@/utils/md5'
import * as api from '@/api'
import {throttle} from 'lodash';

AgoraRTC.setLogLevel(4)
let selfLocation = {
  location: '',
  view: ''
}
// let userMap = {};
const appid = 'f27f60d5113b45aaac36a8f0b07c56a2'
var client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8"
});
export default {
  name: 'App',
  components: {
    AudioPlayer,
    RoomController,
    DemoController,
    MediaTool,
    ActionTool,
    // BgmTool
  },
  data() {
    return {
      debug: false,
      fake: false,
      test: 'v1',
      showBgm: true,
      mediaUrl: mediaUrl,
      urlPrefix: 'https://shelegeji.com/storage/avatar/',
      userPrefix: 'https://shelegeji.com/storage/user/',
      // urlPrefix: 'https://wt-vr.tt/storage/avatar/',
      // userPrefix: 'https://wt-vr.tt/storage/user/',
      renderUi: false,
      mediaDialog: false,
      mediaType: 'img',
      mediaImg: '',
      mediaVideo: '',
      title: '',
      showModal: false,
      showMessageList: false,
      messageList: [],
      fakeList: [
        '钢铁侠',
        '蜘蛛侠',
        '蚁人',
        '美国队长',
        '绿巨人',
        '金刚狼',
        '太空人',
      ],
      scene: '',
      room: '',
      multi: '',
      type: '',
      id: '',
      showNext: false,
      share_uid: '',
      bound: false,
      connectLoading: false,
      imgList: [
        // imgUrl + '/imgs/type10/_6.png',
        // imgUrl + '/imgs/type1/_6.png',
        // imgUrl + '/imgs/type2/_6.png',
        // imgUrl + '/imgs/type12/_6.png',
        // imgUrl + '/imgs/type13/_6.png',
        // imgUrl + '/imgs/type3/_6.png',
        // imgUrl + '/imgs/type17/_6.png',
        // imgUrl + '/imgs/type18/_6.png',
        // imgUrl + '/imgs/type4/_6.png',
        // imgUrl + '/imgs/type15/_6.png',
        // imgUrl + '/imgs/type16/_6.png',
        // imgUrl + '/imgs/type5/_6.png',
        // imgUrl + '/imgs/type6/_6.png',
        // imgUrl + '/imgs/type7/_6.png',
        // imgUrl + '/imgs/type8/_6.png',
        // imgUrl + '/imgs/type9/_6.png',
        // imgUrl + '/imgs/type11/_6.png',
        // imgUrl + '/imgs/type14/_6.png',
      ],
      back: '',
      icon: '',
      avatar: localStorage.getItem('avatar') || '',
      uid: localStorage.getItem('uid') || '',
      uname: localStorage.getItem('uname') || '',
      msg: '',
      msgType: {
        uid: 'public',
        uname: '公共',
        avatar: require('./assets/public.png')
      },
      groupMap: {},
      channel: '',
      joined: false,
      audioTrack: null,
      remoteUsers: {},
      show: false,
      groupMember: [],
      userCount: 0,
      photo: '',
      camera: false,
      nextLoading: false,
      activity_id: '',
      avatar_name: '',
      touchX: '',
      previewIdx: 6,
      showVideo: false,
      userMap: {},
      roomInfo: {},
      isMuted: false,
      showAction: false,
      action: false,
      actioning: false,
      bgmTimer: null,
      iframeInit: false,
      videoComplete: 0,
      audioComplete: 0,
      videoTimer: null
    }
  },
  watch: {
    groupMap: {
      handler(nv) {
        let tx = selfLocation.location.tx;
        let ty = selfLocation.location.ty;
        let tz = selfLocation.location.tz;
        let key = `${tx}_${ty}_${tz}`

        if (nv[key] && nv[key].length > 1) {
          // 存在此key说明当前热点是群组, 展示群组列表
          this.show = true;
          let arr = []
          nv[key].forEach(item => {
            if (item !== this.uid) {
              arr.push(this.userMap[item])
            }
          })
          // // 判断新旧频道是否一致
          // if (this.channel != 'channel' + key) {
          //   // 更新频道信息
          //   this.channel = 'channel' + key;
          //   // 离开之前频道
          //   if (this.joined) {
          //     this.handleLeave()
          //   }
          // }
          // 频道中只有单人的话会退出,有人进来时需要加入频道
          // if (!this.joined) {
          //   // 加入频道
          //   this.handleJoin()
          // }
          this.groupMember = arr;
        }  else {
          // 只有自己存在的情况, 省流
          // if (this.joined) {
          //   this.handleLeave()
          // }
          this.groupMember = []
        }
      },
    },
  },
  methods: {
    getVideoCapture() {
      var videoBox = document.getElementById('member' + this.uid);
      var video = videoBox.getElementsByTagName('video')[0];
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas to PNG
      var dataURL = canvas.toDataURL('image/jpeg');
      localStorage.setItem('video', dataURL);
      // // Create a link to download PNG
      // var link = document.createElement('a');
      // link.download = 'frame.jpg';
      // link.href = dataURL;
      // link.click();
    },
    resetBack() {
      let back = localStorage.getItem('avatar');
      back = back.replace('avatar', 'user/' + this.uid)
      this.back = back.replace('_6', '_0');
      this.action = false;
      this.actioning = false
    },
    startActions(type) {
      if (!this.showAction) {
        return;
      }
      this.action = type;
      let imgUrl = '';
      let imgUrlq = '';
      let currentImageIndex= 1;
      let limit = 1;
      if (type == 'hello') {
        imgUrl = 'https://shelegeji.com/storage/action/hello-b/c_dzh_b_.';
        imgUrlq = 'https://shelegeji.com/storage/action/hello-q/c_dzh_q_.';
        limit = 83
      }
      if (type == 'sitdown') {
        imgUrl = 'https://shelegeji.com/storage/action/sitdown-b/c_zx_b.';
        imgUrlq = 'https://shelegeji.com/storage/action/sitdown-q/c_zx_q.';
        limit = 60
      }
      if (type == 'walk') {
        imgUrl = 'https://shelegeji.com/storage/action/walk-b/c_xz_b_.';
        imgUrlq = 'https://shelegeji.com/storage/action/walk-q/c_xz_c.';
        limit = 29
      }
      this.back = imgUrl + '00000.png';
      let displayNextImage = () => {
        // 创建一个新的图片元素
        const img = document.createElement('img');

        // 添加加载完成事件监听器
        img.addEventListener('load', () => {
          currentImageIndex = currentImageIndex + 1;
          // 加载下一张图片
          loadNextImage();
        });

        let url = '';
        if (currentImageIndex <10) {
          url = imgUrl + '0000' + currentImageIndex + '.png'
        } else {
          url = imgUrl + '000' + currentImageIndex + '.png'
        }
        // 设置图片路径
        img.src =  url
        this.back = url
      }

      let loadNextImage = () => {
        this.actioning = true;
        currentImageIndex = currentImageIndex + 1;
        // 创建一个新的图片元素，但不添加到DOM中
        const nextImg = document.createElement('img');
        // 添加加载完成事件监听器
        nextImg.addEventListener('load', () => {
          // 加载下一张图片
          if (currentImageIndex >= limit) {
            this.actioning = false;
            if (this.action == 'sitdown') {
              this.action = 'sitting'
              send({
                type: 'location',
                action: this.action,
                actionImg: 'https://shelegeji.com/storage/action/sitting-q/c_zz_zc.png',
                location: selfLocation.location,
                view: selfLocation.view,
              })
              this.back = 'https://shelegeji.com/storage/action/sitting-b/c_zz_bc.png'
            } else {
              this.resetBack();
            }
            return
          } else {
            setTimeout(() => {
              loadNextImage();
            }, 60)
          }
        });

        let url = '';
        let urlq = ''
        if (currentImageIndex <10) {
          url = imgUrl + '0000' + currentImageIndex + '.png'
          urlq = imgUrlq + '0000' + currentImageIndex + '.png'

        } else {
          url = imgUrl + '000' + currentImageIndex + '.png'
          urlq = imgUrlq + '000' + currentImageIndex + '.png'
        }
        send({
          type: 'location',
          action: this.action,
          actionImg: urlq,
          location: selfLocation.location,
          view: selfLocation.view,
        })
        // 设置图片路径
        nextImg.src =  url
        this.back = url
      }

      // 初始加载第一张图片
      displayNextImage();
    },

    handleActions(obj) {
      if (!this.showAction) {
        return;
      }
      this.startActions(obj.action)
      this.sendMessageToIframe({type: obj.type, action: obj.action})
    },
    handleMsgType(command) {
      if (command !== 'public' && command !== 'ai_pic' && command !== 'ai') {
        let obj = {}
        obj.uid = this.userMap[command].uid;
        obj.avatar = this.userMap[command].avatar;
        obj.uname = this.userMap[command].uname;
        this.msgType = obj;
      } else if (command == 'public') {
        this.msgType = {
          uid: 'public',
          uname: '公共',
          avatar: require('./assets/public.png')
        }
      } else if (command == 'ai_pic') {
        this.msgType = {
          uid: 'ai_pic',
          uname: '小安绘图',
          avatar: require('./assets/ai_pic.png')
        }
      } else if (command == 'ai') {
        this.msgType = {
          uid: 'ai',
          uname: '小安助手',
          avatar: require('./assets/ai.png')
        }
      }
    },
    showMediaDialog() {
      this.mediaDialog = true
      if (this.roomInfo && this.roomInfo.img) {
        this.mediaImg = mediaUrl + '/' + this.roomInfo.img.url
      }
      if (this.roomInfo && this.roomInfo.video) {
        this.mediaVideo = mediaUrl + '/' +this.roomInfo.video.url
        setTimeout(() => {
          let video = document.getElementById('mediaVideo')
          if (video && this.roomInfo.video.time && Math.abs(video.currentTime - this.roomInfo.video.time) >= 2) {
            video.currentTime = this.roomInfo.video.time;
          }
        }, 500)
      }
    },
    updateMedia() {
      if (this.roomInfo && this.roomInfo.img) {
        this.mediaImg = mediaUrl + '/' + this.roomInfo.img.url
      }
      if (this.roomInfo && this.roomInfo.video) {
        this.mediaVideo = mediaUrl + '/' +this.roomInfo.video.url
        setTimeout(() => {
          let video = document.getElementById('mediaVideo')
          if (video && this.roomInfo.video.time && Math.abs(video.currentTime - this.roomInfo.video.time) >= 2) {
            video.currentTime = this.roomInfo.video.time;
          }
        }, 500)
      }
    },
    updateBgm() {
      if (!this.bgmTimer) {
        this.bgmTimer = setInterval(() => {
          this.showBgm = true;
          let bgm = document.getElementById('bgm');
          if (bgm && this.roomInfo && this.roomInfo.bgm) {
            if(this.roomInfo.bgm.status == 'on') {
              if (bgm.paused) {
                bgm.play().then(()=>{
                  console.log('可以自动播放');
                  bgm.addEventListener('ended', this.bgmEnd, false);
                }).catch((err)=>{
                  this.showBgm = false;
                  bgm.removeEventListener('ended', this.bgmEnd, false);
                });
              }
            } else {
              if (!bgm.paused) {
                bgm.pause()
              }

            }
          }
        }, 1000)
      }
    },
    bgmEnd() {
      this.audioComplete = this.audioComplete + 1;
    },
    onCopy() {
      this.$message.success('复制成功!');
    },
    addFake(obj) {
      if (!this.fake && this.imgList.length) {

        let msg = {
          uname: '小安',
          type: 'welcome',
          content: '你好！欢迎来到' +this.title+'的元宇宙，我是你的助理小安，你有什么问题都可以切换至小安频道问我，我会尽力回答你的问题。'
        }
        this.messageList.push(msg);

        this.fake = true;
        let len = this.imgList.length;
        // let randomIdx = Math.floor(Math.random() * len)
        // let avatar = this.imgList[randomIdx].name
        let avatar = 'type23';
        this.sendMessageToIframe({type: obj.type, uname: '小安', avatar: avatar})
      }
    },
    addImg(obj) {
      this.sendMessageToIframe({type: obj.type, content: obj.url, list: obj.list, action: obj.action})
    },
    muteAudio(flag) {
      this.isMuted = flag
      if (this.audioTrack) {
        this.audioTrack.setMuted(flag)
      }
    },
    sceneChange(scene) {
      let obj = {
        type: 'scene',
        content: scene,
      }
      send(obj)
    },
    async initTracks() {
      this.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
    },
    async onPublished (user, mediaType) {
      await client.subscribe(user, mediaType)
      this.$set(this.remoteUsers, user.uid, user)
    },
    onUnpublished(user) {
      this.$delete(this.remoteUsers, user.uid);
    },
    // 加入音频聊天
    async handleJoin() {
      if (this.debug) {
        return;
      }
      if (this.channel) {
        client.on("user-published", this.onPublished)
        client.on("user-unpublished", this.onUnpublished);
        await client.join(appid, this.channel, null, this.uid)
        await this.initTracks()
        await client.publish([this.audioTrack])
        this.joined = true;
        this.$notify({
          title: '成功',
          message: '加入聊天室',
          type: 'success'
        });
        // if (this.isMuted) {
        //   this.audioTrack.setMuted(this.isMuted)
        //
        // }
      }
    },
    // 离开音频聊天
    async handleLeave() {
      if (this.audioTrack) {
        this.audioTrack.close()
        this.audioTrack = null
      }
      this.remoteUsers = {}
      await client.leave()
      this.joined = false
      this.$notify({
        title: '成功',
        message: '离开聊天室',
        type: 'success'
      });
    },
    handleConnect() {
      // function isEnglish(str) {
      //   return /^[A-Za-z]+$/.test(str);
      // }
      // if (!isEnglish(this.uid)) {
      //   this.$message.error('请输入英文字符')
      //   return;
      // }
      this.connectLoading = true;
      // 超时处理
      setTimeout(() => {
        if (this.connectLoading) {
          this.connectLoading = false
        }
      }, 3000)
      contactSocket(this.uid, {
        onBroadcast: this.onBroadcast,
        onPrivate: this.onPrivate,
        onMsg: this.onMsg,
        onBind: this.onBind,
        onLocation: this.onLocation,
        onLeave: this.onLeave,
        onInit: this.onInit,
        onClose: this.onClose,
        onFloor: this.onFloor,
        onRoom: this.onRoom,
        onOffer: this.onOffer,
        onAnswer: this.onAnswer,
        onJoin: this.onJoin,
        onCandidate: this.onCandidate
      })
    },
    handleDisconnect() {
      if (this.joined) {
        this.handleLeave()
      }
      close()
    },
    handleSend(type) {
      if (this.msgType.uid == 'ai' || this.msgType.uid == 'ai_pic') {
        if (this.msgType.uid == 'ai') {
          let self = {
            uname: this.uname,
            content: this.msg
          }
          this.messageList.push(self);
          setTimeout(() => {
            this.handleScrollBottom()
          }, 100)
          api.addChat({
            n: 1,
            model: "gpt-4",
            messages: [
              { "role": "user", "content": this.msg.replace('@小安', '')}
            ]
          }).then(res => {
            if (res.data && res.data.data && res.data.data.choices && res.data.data.choices[0]) {
              let obj = {
                uname: '小安',
                type: 'chatgpt',
                content: res.data.data.choices[0].message.content
              }
              this.messageList.push(obj);
            }
            setTimeout(() => {
              this.handleScrollBottom()
            }, 100)
          })
        }
        if (this.msgType.uid == 'ai_pic') {
          let self = {
            uname: this.uname,
            content: this.msg
          }
          this.messageList.push(self);
          setTimeout(() => {
            this.handleScrollBottom()
          }, 100)
          api.genImg({
            n: 1,
            size: "1024x1024",
            model: "dall-e-3",
            prompt: this.msg.replace('&小安', '')
          }).then(res => {
            if (res.data && res.data.data && res.data.data.data && res.data.data.data[0]) {
              // url
              // revised_prompt
              let obj = {
                uname: '小安',
                type: 'img',
                content: res.data.data.data[0].url
              }
              this.messageList.push(obj);
            }
            setTimeout(() => {
              this.handleScrollBottom()
            }, 100)
          })
        }
      } else {
        if (type === 'broadcast') {
          if (this.msg.substring(0,3) == '@小安') {
            let self = {
              uname: this.uname,
              content: this.msg
            }
            this.messageList.push(self);
            setTimeout(() => {
              this.handleScrollBottom()
            }, 100)
            api.addChat({
              n: 1,
              model: "gpt-4",
              messages: [
                { "role": "user", "content": this.msg.replace('@小安', '')}
              ]
            }).then(res => {
              if (res.data && res.data.data && res.data.data.choices && res.data.data.choices[0]) {
                let obj = {
                  uname: '小安',
                  type: 'chatgpt',
                  content: res.data.data.choices[0].message.content
                }
                this.messageList.push(obj);
              }
              setTimeout(() => {
                this.handleScrollBottom()
              }, 100)
            })
          }
          else if (this.msg.substring(0,3) == '&小安') {
            let self = {
              uname: this.uname,
              content: this.msg
            }
            this.messageList.push(self);
            setTimeout(() => {
              this.handleScrollBottom()
            }, 100)
            api.genImg({
              n: 1,
              size: "1024x1024",
              model: "dall-e-3",
              prompt: this.msg.replace('&小安', '')
            }).then(res => {
              if (res.data && res.data.data && res.data.data.data && res.data.data.data[0]) {
                // url
                // revised_prompt
                let obj = {
                  uname: '小安',
                  type: 'img',
                  content: res.data.data.data[0].url
                }
                this.messageList.push(obj);
              }
              setTimeout(() => {
                this.handleScrollBottom()
              }, 100)
            })
          } else {
            let obj = {
              type,
              content: this.msg,
              uid: this.uid
            }
            send(obj)
          }
        }
        if (type === 'private') {
          let obj = {
            type,
            content: this.msg,
            uid: this.uid,
            target: this.msgType.uid,
            targetUname: this.msgType.uname
          }
          send(obj)
        }

      }

      this.msg = ''
    },
    onMsg(msg) {
      console.log(msg, '>>>>>onMsg')
    },
    // socket绑定成功消息
    onBind(msg) {
      // this.$notify({
      //   title: '成功',
      //   message: '登录成功',
      //   type: 'success'
      // });
      localStorage.setItem('logined', 1)
      let back = localStorage.getItem('avatar');
      // http://wt-vr.tt/storage/avatar/eee/_6.png
      // http://wt-vr.tt/storage/user/oC3K84sd-yNk2Y3d1GcrJmul64V0/eee/_6.png
      back = back.replace('avatar', 'user/' + this.uid)
      this.back = back.replace('_6', '_0');
      this.icon = back.replace('_6', '_icon');
      this.sendMessageToIframe({type: 'self', content: this.uid})
      this.connectLoading = false;
      this.bound = true
      this.showNext = false;

      // 登录成功后。获取本地流
      setTimeout(() => {
        this.genLocalStream().then(() => {
          send({
            type: 'join',
            content: ''
          })
          this.videoTimer = setInterval(() => {
            this.getVideoCapture();
          }, 100)
          // let list = msg.list
          // list.forEach(item => {
          //   if (item !== this.uid) {
          //     this.genRtc(item);
          //   }
          // })
        });
      }, 0)

      // 加入频道, 通知他人
      this.handleJoin()
      // console.log(msg, '>>>>>onBind')
    },
    onBroadcast(msg) {
      // this.$notify.info({
      //   title: '来自' + msg.uid,
      //   message: msg.content
      // });
      this.messageList.push(msg);
      // 滚动到底部
      this.handleScrollBottom();
      console.log(msg, '>>>>>onBroadcast')
    },
    onPrivate(msg) {
      this.messageList.push(msg);
      // 滚动到底部
      this.handleScrollBottom();
      console.log(msg, '>>>>>onPrivate')
    },
    // 位置信息更新
    onLocation(msg) {
      let _userMap = JSON.parse(JSON.stringify(this.userMap))
      _userMap[msg.uid] = {..._userMap[msg.uid], ...msg,};
      this.userMap = _userMap
      this.userCount = Object.keys(this.userMap).length
      this.sendMessageToIframe(msg)
      // console.log(msg, '>>>>>onLocation')
    },
    // 有人离开
    onLeave(msg) {
      // 清除相应的user信息
      let _userMap = JSON.parse(JSON.stringify(this.userMap))
      delete _userMap[msg.content]
      this.userMap = _userMap
      this.userCount = Object.keys(this.userMap).length
      this.sendMessageToIframe(msg)
      // console.log(msg, '>>>>>onLeave')
    },
    // 楼层切换
    onFloor(msg) {
      let _userMap = JSON.parse(JSON.stringify(this.userMap))
      delete _userMap[msg.content]
      this.userMap = _userMap
      this.userCount = Object.keys(this.userMap).length
      this.sendMessageToIframe(msg)
    },
    // 房间信息
    onRoom(msg) {
      this.roomInfo = msg;
      this.updateBgm();
      this.updateMedia();
      if (this.iframeInit) {
        this.sendMessageToIframe({type: 'room', content: msg})
      } else {
        this.iframeInit = true;
        // iframe可能没有加载完成
        setTimeout(() => {
          this.sendMessageToIframe({type: 'room', content: msg})
        }, 1000)
      }
    },
    // 初始化信息
    onInit(msg) {
      this.sendMessageToIframe({type: 'self', content: this.uid})
      setTimeout(() => {
        this.userMap = msg.content;
        this.userCount = Object.keys(this.userMap).length
        this.sendMessageToIframe(msg)
      }, 0)
      // console.log(msg, '>>>>>onInit')
    },
    // 离开
    onClose() {
      // this.$notify.success({
      //   title: '提示',
      //   message: '已退出',
      // });
      this.bound = false
      this.handleLeave();
    },
    // 发送消息到iframe
    sendMessageToIframe(msg) {
      this.$refs.iframeRef.contentWindow.postMessage(JSON.stringify(msg));
    },
    // 接收来自iframe的消息
    receiveMessageFromIframe(event) {
      if (!this.$refs.iframeRef) {
        return;
      }
      if (event.source !== this.$refs.iframeRef.contentWindow) {
        return;
      }
      try {
        let msg = JSON.parse(event.data);
        // 场景点位视角更新信息
        if (msg.type == 'location') {
          // 收到更新点位信息
          selfLocation.location = msg.location;
          selfLocation.view = msg.view;
          if (!this.action) {
            send({
              type: 'location',
              location: selfLocation.location,
              view: selfLocation.view,
            })
          }

        }
        // 初始化消息，进入新场景时发送
        if (msg.type == 'init') {
          send({
            type: 'init',
          })
          setTimeout(() => {
            this.addFake({type: 'add-fake'});
          }, 1000)
        }
        // 收到更新群组信息
        if (msg.type == 'group') {
          if (JSON.stringify(this.groupMap) !== JSON.stringify(msg.value)) {
            this.groupMap = msg.value;
          }
        }
        // 更新document-title
        if (msg.type == 'title') {
          this.title = msg.content;
        }
        // 初始化消息，进入新场景时发送
        if (msg.type == 'scene') {
          this.sceneChange(msg.content)
        }
        // 移动时
        if (msg.type == 'sceneChange') {
          if (this.showAction) {
            this.action = 'walk';
            this.startActions('walk');
          }
        }

        if (msg.type == 'sceneChanged') {
          if (this.action == 'sitting') {
            this.resetBack();
          }
        }

        // 房主新增视频
        if (msg.type == 'video') {
          send({
            type: 'video',
            content: msg.content
          })
        }
        if (msg.type == 'videoComplete') {
          this.videoComplete = this.videoComplete + 1;
        }
        // 房主新增图片
        if (msg.type == 'img') {
          send({
            type: 'img',
            content: msg.content
          })
        }
        // 点击图片或视频进行预览
        if (msg.type == 'preview') {
          if (this.roomInfo.host != this.uid) {
            this.mediaDialog = true;
            this.mediaType = msg.mediaType
          }
        }
        // 判断是否嵌套载入代码
        if (msg.type == 'renderUi') {
          this.renderUi = msg.content;
        }
        // 判断是否嵌套载入代码
        if (msg.type == 'url') {
          if (wx) {
            wx.miniProgram.navigateTo({url: msg.content});
          }
        }
        // 判断是否嵌套载入代码
        if (msg.type == 'redirect') {
          // let redirectUrl = `https://anjing.shelegeji.com/#/index?scene=${msg.content}&room=${this.room}&type=${this.type}&id=${this.id}&title=${this.title}&share_uid=${this.share_uid}&multi=1`
          this.scene = false;
          send({
            type: 'floor',
          })
          this.$nextTick(() => {
            this.scene = msg.content;
            localStorage.setItem('scene', this.scene);
            bindUser(localStorage.getItem('uid'))
          })
        }
      } catch (e) {
        console.log(e, 'iframe信息错误')
      }
    },
    // 其他
    filterSelf(msg) {
      // 先去除自身的content
      let _msg = JSON.parse(JSON.stringify(msg));
      delete _msg.content[localStorage.getItem('uid')];
      return _msg
    },
    uuid(len, radix, isUname) {
      var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
      var uuid = [], i;
      radix = radix || chars.length;
      if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
      } else {
        let r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | Math.random()*16;
            uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
          }
        }
      }
      if (isUname) {
        return '_' + uuid.join('');
      } else {
        return 'vr_' + uuid.join('');
      }
    },
    genUname() {
      let idx = Math.floor(Math.random() * 6.999);
      this.uname = this.fakeList[idx] + this.uuid(3, 32, true);
    },
    genUnameFake() {
      let idx = Math.floor(Math.random() * 6.999);
      return this.fakeList[idx] + this.uuid(3, 32, true);
    },
    initSwiper() {
      let initialSlide = 0;
      if (localStorage.getItem('avatar')) {
        initialSlide = this.imgList.findIndex(item => item.avatar == localStorage.getItem('avatar'))
        if (initialSlide == -1){
          initialSlide = 0
        } else {
          this.avatar_name = this.imgList.filter(item => item.avatar == localStorage.getItem('avatar'))[0].name
          this.activity_id = this.imgList.filter(item => item.avatar == localStorage.getItem('avatar'))[0].activity_id
        }
      }
      let that = this;
      let swiper = new Swiper('#user-swiper', {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        slideToClickedSlide: true,
        initialSlide: initialSlide,
        on: {
          progress: function() {
            if (that.avatar != that.imgList[this.activeIndex].avatar) {
              that.avatarChange(that.imgList[this.activeIndex])
            }
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              let scale = 1 - Math.abs(slideProgress) / 9;
              slide.transform('scale(' + scale + ')');
            }
          },
          setTransition: function(swiper, transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition);
            }
          },
          transitionEnd: function () {
            if (that.avatar != that.imgList[this.activeIndex].avatar) {
              that.avatarChange(that.imgList[this.activeIndex])
            }
          }
        },
        lazy: {
          loadPrevNext: true,
        },
      })
    },
    avatarChange(item) {
      this.avatar = item.avatar
      this.avatar_name = item.name
      this.activity_id = item.activity_id
      localStorage.setItem('avatar', this.avatar)
    },
    handleShowModal() {
      this.showModal = !this.showModal;
      this.showNext = false;
    },
    // 长按弹出上拉操作面板
    onTouchStart (e) {
      this.touchX = e.targetTouches[0].screenX
      e.preventDefault() // 阻止系统默认事件
    },
    onTouchMove: throttle(function(e){
      const moveX = e.targetTouches[0].screenX
      if (moveX - this.touchX > 5) {
        this.previewIdx = this.previewIdx - 1
        if (this.previewIdx <= 0) {
          this.previewIdx = 12
        }
      }
      if (moveX - this.touchX < -5) {
        this.previewIdx = this.previewIdx + 1
        if (this.previewIdx >= 12) {
          this.previewIdx = 0
        }
      }
      this.touchX = moveX
    },100),
    async handleNext() {
      this.nextLoading = true
      await this.addAvatar(true);
      this.nextLoading = false
      this.showNext = true;
    },
    async handleLogin(isRoom, gened) {
      this.showModal = false
      if (!gened) {
        this.nextLoading = true
        await this.addAvatar(false);
        this.nextLoading = false
      }
      if (this.connectLoading) {
        return;
      }
      if (!this.scene) {
        this.$notify.error({
          title: '提示',
          message: '未加载场景',
        });
        return;
      }
      localStorage.setItem('uname', this.uname);
      if (!this.uid) {
        this.uid = this.uuid(8, 32);
        localStorage.setItem('uid', this.uid)
      }
      // 如果是加入房间的人，无法创建房间
      if (isRoom) {
        this.room = this.uuid(8, 32);
        localStorage.setItem('room', this.room)
        localStorage.setItem('host', this.uid)
      }
      this.handleConnect();
    },
    async handleUpdate() {
      await this.addAvatar(false);
      localStorage.setItem('uname', this.uname);
      localStorage.setItem('avatar', this.avatar)
      this.back = '';
      this.icon = '';
      this.$nextTick(() => {
        let back = this.avatar
        back = back.replace('avatar', 'user/' + this.uid)
        this.back = back.replace('_6', '_0');
        this.icon = back.replace('_6', '_icon');
      })
      this.showModal = false;
    },
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.scrollDiv;
        if(scrollElem) {
          scrollElem.scrollTo({ top: scrollElem .scrollHeight });
        }
      });
    },
    handleShare() {
      if (wx) {
        wx.miniProgram.navigateTo({url: `/pages/propcompany/vrshare?scene=${this.scene}&room=${this.room}&type=${this.type}&id=${this.id}&title=${this.title}&share_uid=${this.share_uid}`});
      }
    },
    // 调用函数来获取摄像头图像并转换为Base64格式
    startMedia() {
      var hasVideo = document.getElementById("video");
      if (hasVideo) {
        hasVideo.remove();
      }
      let that = this;
      navigator.mediaDevices.getUserMedia({ video: true, audio: false, })
        .then(function (_stream) {
          that.showVideo = true
          // 创建一个video元素来显示摄像头图像
          var video = document.createElement('video');
          video.srcObject = _stream;
          video.setAttribute("id", "video");
          video.setAttribute("muted", "true");
          video.setAttribute("autoplay", "true");
          video.setAttribute("playsinline", "true");
          video.setAttribute("x-webkit-airplay", "true");
          video.setAttribute("x5-video-player-fullscreen", "true");
          document.getElementsByClassName('video-wrapper')[0].append(video)
          // var video = document.getElementById('video');
          stream = _stream
          that.camera = true
          that.test = JSON.stringify(_stream);
          // 当video元素加载完成后，将图像绘制到Canvas并转换为Base64格式
          video.play()
        })
        .catch(function (error) {
          console.error('Error accessing camera:', error);
        });
    },
    stopCamera() {
      if (stream) {
        stream.getTracks().forEach(function (track) {
          track.stop();
          stream = null;
        });
      }
      setTimeout(() => {
        this.camera = false
        this.showVideo = false
      }, 500)
    },
    captureCameraImage() {
      // 创建一个Canvas元素
      var video = document.getElementById('video');
      var canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // 在Canvas上绘制摄像头图像
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      // 将Canvas中的图像转换为Base64格式
      var base64Image = canvas.toDataURL('image/jpeg');
      // 输出Base64格式的图像
      this.photo = base64Image;
      this.stopCamera();
    },
    takePhoto(type) {
      if (type == 'camera') {
        this.photo = '';
        this.camera = false
        this.startMedia();
      }
      if (type == 'take') {
        this.captureCameraImage();
      }
    },
    async getAvatarList() {
      try {
        const {data} = await api.getAvatarList();
        this.imgList = data.data.data.map(item => ({...item, avatar: this.urlPrefix + item.name + '/_6.png'}));
      } finally {
      }
    },
    async addAvatar(flag) {
      let arr = this.avatar.split('/');
      try {
        let obj = {
          open_id: this.uid,
          avatar_name: this.avatar_name,
        }
        if (this.photo && flag) {
          obj.tencent_id = this.activity_id;
          obj.photo = this.photo
        }
        const {data} = await api.addAvatar(obj);
      } finally {
      }
    },
    genLocalStream() {
      return new Promise((resolve, reject) => {
        let that = this;
        // 获取本地视频流
        navigator.mediaDevices.getUserMedia({ video: { width: 40, height: 40 }, audio: false })
            .then(function (stream) {
              localStream = stream
              that.genStreamVideo(stream, that.uid);
              resolve();
              // localVideo.srcObject = stream;
            })
            .catch(function (error) {
              resolve()
              console.error('getUserMedia error:', error);
            });
      })
    },
    genRtc(uid) {
      return;
      if (rtcMap[uid]) {
        rtcMap[uid].close();
        rtcMap[uid] = null;
      }
      let that = this;
      // 创建PeerConnection对象
      rtcMap[uid] = new RTCPeerConnection(configuration);

      // 将本地视频流添加到PeerConnection中
      if (localStream) {
        localStream.getTracks().forEach(track => rtcMap[uid].addTrack(track, localStream));
      }

      // 当有远程流传入时，将其显示在remoteVideo元素上
      rtcMap[uid].ontrack = function (event) {
        if (event.track.kind === 'video') {
          console.log(event, 88)
          that.genStreamVideo(event.streams[0], uid)
        }
      };

      that.sendOffer(uid)
    },
    genStreamVideo(stream, uid) {
      if (document.getElementById("remoteVideo" + uid)) {
        document.getElementById("remoteVideo" + uid).remove();
      }
      var video = document.createElement('video');
      video.srcObject = stream;
      video.setAttribute("id", "remoteVideo" + uid);
      video.setAttribute("class", "video");
      video.setAttribute("muted", "true");
      video.setAttribute("autoplay", "true");
      video.setAttribute("playsinline", "true");
      video.setAttribute("x-webkit-airplay", "true");
      video.setAttribute("x5-video-player-fullscreen", "true");
      document.getElementById('member' + uid).append(video)
    },
    sendOffer(uid) {
      // 创建Offer
      rtcMap[uid].createOffer()
          .then(offer => rtcMap[uid].setLocalDescription(offer))
          .then(() => {
            // 将Offer发送给另一端
            send({
              type: 'offer',
              target: uid,
              content: rtcMap[uid].localDescription
            });
          });
    },
    onOffer(message) {
      if (message.uid == this.uid) {
        return;
      }
      let uid = message.uid
      console.log(message, 'offer')
      rtcMap[uid] = new RTCPeerConnection(configuration);
      rtcMap[uid].setRemoteDescription(new RTCSessionDescription(message.content));
      // 添加本地视频流到PeerConnection
      if (localStream) {
        localStream.getTracks().forEach(track => rtcMap[uid].addTrack(track, localStream));
      }
      // 创建Answer
      rtcMap[uid].createAnswer()
          .then(answer => rtcMap[uid].setLocalDescription(answer))
          .then(() => {
            // 将Answer发送给对方
            send({
              type: 'answer',
              target: uid,
              content: rtcMap[uid].localDescription
            });
          });

      // 处理ICE Candidate
      rtcMap[uid].onicecandidate = function (event) {
        console.log(event.candidate, 'receive')
        if (event.candidate) {
          send({
            type: 'candidate',
            target: uid,
            content: event.candidate
          });
        }
      };
    },
    onJoin(message) {
      console.log(message.uid, 55555);
      // 有新人加入后重新建立连接
      setTimeout(() => {
        this.genRtc(message.uid);
      }, 1500)
    },
    onAnswer(message) {
      if (message.uid == this.uid) {
        return;
      }
      let uid = message.uid
      console.log(message, 'answer')
      rtcMap[uid].setRemoteDescription(new RTCSessionDescription(message.content));
    },
    onCandidate(message) {
      if (message.uid == this.uid) {
        return;
      }
      let uid = message.uid
      console.log(message, 'candidate')
      setTimeout(() => {
        rtcMap[uid].addIceCandidate(new RTCIceCandidate(message.content));
      }, 1000)
      // if (message.content.candidate) {
      //   var type = message.content.candidate.split(" ")[7];
      //   if (type != "relay") {
      //     return;
      //   } else {
      //     let uid = message.uid
      //     console.log(message, 'candidate')
      //     rtcMap[uid].addIceCandidate(new RTCIceCandidate(message.content));
      //   }
      // }
    }
  },
  created() {
    // 去除房主信息
    localStorage.setItem('host', '')
    if (this.$route && this.$route.query && this.$route.query.scene) {
      // // 是否有多楼层跳转, 有此字段的话, 语音频道不断
      // this.multi = this.$route.query.multi ? this.$route.query.multi : '';
      // if (this.multi) { // 场景内楼层跳转
      //   this.scene = this.$route.query.scene;
      //   this.room = localStorage.getItem('room') || this.$route.query.room;
      //   // vr的商户类型
      //   this.type = localStorage.getItem('type') || this.$route.query.type;
      //   // vr的商户id
      //   this.id = localStorage.getItem('id') || this.$route.query.id;
      //   this.share_uid = localStorage.getItem('share_uid') || this.$route.query.share_uid;
      //   this.channel = localStorage.getItem('channel') || ('vr' + md5(localStorage.getItem('scene') + this.room));
      // } else {
      //
      // }
      this.scene = this.$route.query.scene;
      this.room = this.$route.query.room || '';
      // vr的商户类型
      this.type = this.$route.query.type || '';
      // vr的商户id
      this.id = this.$route.query.id || '';
      // vr的分享人id
      this.share_uid = this.$route.query.share_uid || '';
      if (this.$route.query.uid) {
        this.uid = this.$route.query.uid || '';
        if (this.$route.query.uid.indexOf('_') == -1) {
          this.uid = '_' + this.$route.query.uid
        }
      }
      if (this.$route.query.UID) {
        if (this.$route.query.UID.indexOf('_') == -1) {
          this.uid = '_' + this.$route.query.UID
        }
      }
      this.channel = 'vr' + md5(this.scene + this.room);
      localStorage.setItem('type', this.type);
      localStorage.setItem('id', this.id);
      localStorage.setItem('share_uid', this.share_uid);
      localStorage.setItem('uid', this.uid);
      localStorage.setItem('scene', this.scene);
      localStorage.setItem('room', this.room);
      localStorage.setItem('channel', this.channel);
    }
  },
  async mounted() {
    if (!localStorage.getItem('uid')) {
      this.uid = this.uuid(5, 32, true);
      localStorage.setItem('uid', this.uid)
    }
      //'oC3K84sd-yNk2Y3d1GcrJmul64V0'
    window.addEventListener("message", this.receiveMessageFromIframe, false);
    // window.onbeforeunload = function() {
    //   return 1;
    // };
    window.addEventListener('unload', () => {
      if (this.joined) {
        this.handleLeave();
      }
      close()
    });
    if (localStorage.getItem('uid') && localStorage.getItem('avatar') && localStorage.getItem('uname') && localStorage.getItem('logined')) {
      this.handleLogin(false)
    }
    if (!localStorage.getItem('uname')) {
      this.genUname();
    }
    await this.getAvatarList();
    this.initSwiper()
  },
  beforeDestroy() {
    if (this.joined) {
      this.handleLeave();
    }
    close()
    clearInterval(this.bgmTimer);
    this.bgmTimer = null;
  },
}
</script>
<style lang="scss">

  #video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
    background: #333;
  }
  .el-dialog__body{
    padding: 10px;
  }
  .el-dialog{
    background: rgba(63,63,63,0.65);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  .el-dropdown-menu__item{
    padding-top: 4px;
    padding-bottom: 4px;

  }
  .menu-icon{
    width: 30px;
    height: 30px;
    background: #fff;
    vertical-align: middle;
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
    margin-right: 5px;
    box-sizing: border-box;
    padding: 2px;
    img{
      @include fit(contain)
    }
  }
  .ai_pic, .ai{
    box-sizing: border-box;
    padding: 5px;
  }

  .menu-name{
    font-size: 13px;
    color: #fff;
    vertical-align: middle;
  }
  .el-dropdown-menu{
    background: #3f3f3f;
    border: 1px solid #3f3f3f;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .el-popper[x-placement^=top] .popper__arrow::after{
    border-top-color: #3f3f3f;
  }
  .el-popper[x-placement^=top] .popper__arrow{
    border-top-color: #3f3f3f;
  }
</style>
<style scoped lang="scss">
  @import "assets/css/normalize.css";
  @mixin border() {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  #app{
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  .container{
    width: 100vw;
    height: 100vh;
    position: relative;
    .count{
      position: fixed;
      bottom: 80px;
      left: 20px;
      z-index: 1;
      font-size: 12px;
      color: #fff;
      padding: 5px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    .groupMember{
      position: absolute;
      left: 0;
      top: 0;
      max-height: 60vh;
      .item{
        width: 50px;
        height: 70px;
        margin-bottom: 10px;
        position: relative;
        img{
          width: 50px;
          height: 50px;
          object-position: center;
          object-fit: contain;
        }
        .name{
          width: 100%;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.35);
          @include elli();
        }
      }
    }
  }
  iframe{
    width: 100vw;
    height: 100vh;
    display: block;
  }
  p{
    padding: 0 12px;
    box-sizing: border-box;
  }
  .box{
    padding: 0 12px;
    box-sizing: border-box;
  }
  .video-modal-mask{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transition: 0.3s ease;
    opacity: 0;
    visibility: hidden;
    .video-modal{
      width: 80vw;
      height: calc(80vw + 100px);
      transform: translateY(20px);
      transition: 0.3s ease;
      transition-delay: 0.1s;
      @include center();
      .video-wrapper{
        width: 80vw;
        height: 80vw;
        border-radius: 6px;
        background: #333;
        position: relative;
        overflow: hidden;
        .video-mask-png{
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          position: absolute;
          z-index: 100;

        }
        .thumb{
          width: 80vw;
          height: 80vw;
          border-radius: 6px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 20;
          object-position: center;
          object-fit: cover;
        }
      }
      .take-btn{
        width: 64px;
        height: 64px;
        margin: 0 auto;
        margin-top: 36px;
      }
    }
  }
  .video-modal-mask-show{
    opacity: 1;
    visibility: visible;

    .video-modal{
      transform: translateY(0px);
    }
  }
  .user-mask{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    .user-modal{
      width: 343px;
      height: 610px;
      padding-bottom: 16px;
      background: #3F3F3F;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: translateY(-30px);
      .welcome-tip{
        height: 54px;
        line-height: 27px;
        font-size: 18px;
        color: #fff;
        padding-top: 16px;
        padding-bottom: 8px;
        text-align: center;
        @include border();
      }
      .ai-container{
        width: 100%;
        @include border();
        box-sizing: border-box;
        padding-bottom: 28px;
        margin-bottom: 15px;
        @include border();
        position: relative;
        .video-container{
          width: 134px;
          height: 134px;
          border-radius: 6px;
          background: #fff;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          .video-mask{
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 6px;
            overflow: hidden;
            z-index: 10;
          }
          .thumb{
            width: 100%;
            height: 100%;
            border-radius: 6px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
            object-position: center;
            object-fit: cover;
          }
          .photo-avatar{
            width: 116px;
            height: 114px;
            @include center();
          }
        }

        .video-tips{
          width: 100%;
          text-align: center;
          font-size: 14px;
          color: #fff;
          padding-top: 8px;
          padding-bottom: 10px;
        }
        .video-re{
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #e2b35b;
          padding-top: 8px;
          position: absolute;
          bottom: 6px;
          left: 0;
        }
      }

      .choose-tip{
        width: 100%;
        text-align: center;
        font-size: 17px;
        color: #FF8E26;
        line-height: 22px;
        margin-bottom: 12px;
        height: 22px;
      }
      .name-tip{
        width: 100%;
        text-align: center;
        font-size: 17px;
        color: #FF8E26;
        line-height: 27px;
        margin-bottom: 8px;
        margin-top: 8px;
        height: 27px;
      }
      .name-input{
        width: calc(100% - 32px);
        height: 40px;
        margin-left: 16px;
        position: relative;
        padding-bottom: 8px;
        margin-bottom: 8px;
        @include border();
        /deep/ .el-input__inner{
          border-radius: 8px;
        }
        .el-icon-refresh{
          position: absolute;
          right: 10px;
          top: 12px;
          color: #5C5C5C;
          cursor: pointer;
        }
      }
      .joinvr{
        width: 311px;
        height: 40px;
        background: #FF8E26;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin: 0 auto;
        font-size: 16px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        display: block;
        outline: none;
        border: none;
        box-shadow: none;
        padding: 0;
      }
      .back-prev{
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #EBAF13;
      }
      .skip{
        width: 60px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #9A9A9A;
      }
      .create-room{
        background: rgba(235,176,22,1)!important;
      }
      /deep/ .is-disabled{
        background-color: #eee;
        pointer-events: none;
      }
      .swiper{
        width: 100%;
        height: 109px;
        margin-bottom: 8px;
        padding-bottom: 14px;
        @include border();
        .swiper-slide{
          width: 118px;
          height: 109px;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 2px solid #fff;
          box-sizing: border-box;
          background-color: #fff;
          transition: 0.3s;
          img{
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: contain;
          }
        }
        .avatar-on{
          border: 2px solid #FF8E26;
        }
      }
    }
    .user-modal2{
      z-index: 150;
      height: 610px;
      .img-box{
        height: 400px;
        width: 80%;
        display: flex;
        box-sizing: border-box;
        padding-top: 0;
        margin: 0 auto;
        position: relative;
        .preview-box{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 20;
          .mark-shape{
            width: 50px;
            height: 100px;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #3F3F3F;
          }
        }
        .img-main{
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          z-index: 10;
          overflow: hidden;
          img{
            width: 100%;
            height: 400px;
            object-fit: contain;
            object-position: center;
            position: absolute;
            bottom: 0;
          }
        }
        .img-mask{
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          filter:brightness(100);
          @include center();
          z-index: 1;
          transition-delay: 3s ease;
        }
        .pointer{
          position: absolute;
          right: 0px;
          bottom: 0;
          z-index: 50;
        }
      }
      .img-box-ani{
        @keyframes img-main-ani {
          0%{
            height: 0%;
          }
          100%{
            height: 100%;
          }
        }
        .img-main{
          animation: img-main-ani 3s ease forwards;
        }

      }
    }
  }
  .message-box{
    width: calc(100% - 40px);
    position: fixed;
    left: 20px;
    bottom: 40px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    .el-input {
      width: calc(100% - 46px - 46px);
    }
    .menu{
      position: absolute;
      right: 46px;
    }
    .target{
      width: 32px;
      height: 32px;
      margin-top: 4px;
      margin-right: 4px;
      background-color: #3f3f3f;
      font-size: 20px;
      text-align: center;
      box-sizing: border-box;
      padding: 2px;
      i{
        position: relative;
        top: -2px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
      .ai_pic, .ai{
        box-sizing: border-box;
        padding: 4px;
      }
    }
    /deep/ .el-input__inner{
      margin-right: 6px;
      border-radius: 8px;
      background: rgba(255,255,255,0.7);
      color: #333;
      padding-right: 46px;
      &::-webkit-input-placeholder {
        font-size: 16px;
        color: #858585;
      }
    }
    .expand{
      background-color: #fff;
      font-size: 20px;
      text-align: center;
      box-sizing: border-box;
      padding: 4px;
      i{
        position: relative;
        top: -2px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    button{
      width: 40px;
      height: 40px;
      background: #FF8E26;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      display: block;
      outline: none;
      border: none;
      box-shadow: none;
      padding: 0;
      font-size: 26px;
    }
    /deep/ .is-disabled{
      background-color: #eee;
      pointer-events: none;
    }
  }
  .message-list{
    position: fixed;
    bottom: 100px;
    left: 20px;
    z-index: 10;
    width: calc(100vw - 180px);
    box-sizing: border-box;
    padding-right: 5px;
    max-height: 135px;
    .message-empty{
      pointer-events: none;
      width: 100%;
      text-align: center;
      height: 60px;
      line-height: 60px;
      font-size: 13px;
      color: #fff;
      background: rgba(0,0,0,0.45);
      border-radius: 8px 8px 8px 8px;
      box-sizing: border-box;
    }
    .message-item{
      /*pointer-events: none;*/
      width: fit-content;
      margin-bottom: 4px;
      line-height: 22px;
      background: rgba(0,0,0,0.45);
      border-radius: 8px 8px 8px 8px;
      padding: 3px 8px;
      box-sizing: border-box;
      .text{
        word-break: break-all;
        font-size: 12px;
        color: #fff;
        .pink{
          color: rgb(210, 116, 29);
        }
      }
      .send-tips{
        display: inline-block;
        font-size: 12px;
        color: rgba(255,255,255,0.3);
        transform: scale(0.8);
      }
      .copy{
        font-size: 12px;
        color: #3a8ee6;
        cursor: pointer;
        float: right;
        position: relative;
        top: 1px;
      }
    }
  }
  .back{
    pointer-events: none;
    opacity: 0.7;
    position: fixed;
    bottom: 83px;
    right: 35px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    img{
      width: 120px;
      height: unset;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .back-action{
    img{
      width: unset;
      height: 120px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .share{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #FF8E26;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 888;
    text-align: center;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    img{
      width: 20px;
      height: 20px;
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .media-show-btn{
    position: fixed;
    bottom: 120px;
    right: 10px;
    z-index: 777;
  }
  .mediaVideo{
    width: 100%;
    height: calc(100vh - 200px);
    margin-top: 30px;
    video{
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }
  .mediaImg{
    width: 100%;
    height: calc(100vh - 200px);
    margin-top: 30px;
    img{
      @include fit(contain);
    }
  }
</style>
