<template>
  <div></div>
</template>

<script>
  export default {
    name: "index",
    props: {
      audioTrack: {
        type: Object,
        default: null
      },
    },
    mounted() {
      if (this.audioTrack) {
        this.audioTrack.play()
      }
      window.addEventListener('beforeunload', () => {
        if (this.audioTrack) {
          this.audioTrack.close()
        }
      });
    },
    beforeDestroy() {
      if (this.audioTrack) {
        this.audioTrack.close()
      }
    },
  }
</script>

<style scoped>

</style>
